import { defineStore } from "pinia";

export const useMobileDashboardPrevious = defineStore({
  id: "MobileDashboardPrevious",
  state: () => (
    {
        value:null
    }
  ),
  getters: {
    getMobileDashboardPrevious(state) {
      return state.value;
    }
  },
  actions: {
    setMobileDashboardPrevious(data) {
      this.value = data;
    }
  },
  persist: {
    storage: localStorage,
    paths: ["mobile_dashboard_previous"]
  },
});
