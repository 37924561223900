<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';
import { useCountriesStore } from '../../stores/countries';

const { t } = useI18n();
const registerStore = useRegisterStore();
const countriesStore = useCountriesStore();
</script>


<template>
	<div class="register-form-field">
		<label>{{ $t('account.register.mobile') }} {{ $t('account.register.number') }}</label>
		<div class="phone-field">
			<input type="tel" :placeholder="$t('account.register.mobile') + $t('account.register.number')" @keyup="registerStore.validateMobileNumber" v-model="registerStore.register_input_fields.mobile_number" class="form-input">
		</div>
		<div class="register-form-list" v-if="registerStore.register_errors.mobile_number.error">
			<div class="password-meter">
				<div class="password-meter-bar">
					<div class="password-meter-bar-inn"></div>
				</div>
			</div> 
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.mobile_number.error === 'error_only_numbers'">
					{{ $t('account.register.error_only_numbers') }}
				</span>
				<span v-else-if="registerStore.register_errors.mobile_number.error === 'error_mobile_number_required'">
					{{ $t('account.register.error_mobile_number_required') }}
				</span>
				<span v-else-if="registerStore.register_errors.hasOwnProperty('mobile_number')">
					{{ registerStore.register_errors.mobile_number.error }}
				</span>
			</p>
		</div>
	</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .phone-field {
	display: block;
}
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .phone-field input::placeholder {
	text-align: right; 
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input { 
	padding: 10px 15px 10px 15px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .phone-field input {
	text-align: right;
} 
</style>