/* eslint-disable */
import { defineStore } from 'pinia';
import _ from 'lodash';
import { v5 as uuidv5 } from 'uuid';
import { useLanguagesStore } from './languages';

export const useVirtualCourtStore = defineStore('virtualCourt', {
	state: () => ({
		selected_fixture: null,
		selected_fixture_mobile: null,
		fixture_in_queue: null,
	}),
	getters: {
		selectedFixtureId(state) {
			if (!state.selected_fixture) return null;
			return state.selected_fixture.id;
		},
		selectedFixture(state) {
			if (!state.selected_fixture) return null;
			return state.selected_fixture;
		},
		getVirtualCourtLanguageLocale() {
			let language = useLanguagesStore().selectedLanguage;
			return language.locale;
		},
		isSportEligible(state) {
			let eligible_sport_ids = [1,2,3,8];
			// alert(state.selectedFixture.sport_id)
			if (eligible_sport_ids.includes(state.selectedFixture.sport_id)) return true;

			return false;
		}
	},
	actions: {
		selectFixtureNew(fixture) {
			this.selected_fixture = fixture;
		},
		selectFixture(fixture) {
			if (this.selected_fixture != null && this.selected_fixture.id == fixture.id) return;

			let targetOrigin = process.env.VUE_APP_VIRTUAL_COURT_URL;
			let fixture_uuid = uuidv5(String(fixture.virtual_live_id), process.env.VUE_APP_VIRTUAL_COURT_NAMESPACE);

			let message_payload = {
				fixture_uuid: fixture_uuid,
				colors: ["#373D46", '#373D46', '#373D46']
			}

			let virtual_live_element = document.getElementsByClassName('virtual-live-frame');

			if (virtual_live_element.length > 0) {
				setTimeout(() => {
					virtual_live_element[0].contentWindow.postMessage(message_payload, targetOrigin);
				}, 100);

				this.selected_fixture = fixture;
			}
		},
		setFixtureInQueue(fixture) {
			this.fixture_in_queue = fixture;
		},
		emptyVirtualCourt() {
			this.selected_fixture = null;
			// this.fixture_in_queue = null;
		}
	},
})