import { defineStore } from 'pinia';
import _ from 'lodash';
import { useCustomerStore } from './customer';
// commit
export const useTimezonesStore = defineStore('timezones', {
	state: () => ({
		timezones: null
	}),
	getters: {
		getTimezones(state){
			return state.timezones
		},
		selectedTimezone(state) {
			const customerStore = useCustomerStore();

			if (customerStore.isLoggedIn) return customerStore.getCustomer.timezone;

			return _.find(state.timezones, { id: 1});
		}
	},
	actions: {
		setTimezones(data) {
			this.timezones = data;
		}
	},
})



