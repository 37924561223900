 /* eslint-disable */
import { defineStore } from 'pinia';
import {applyPatch, createPatch} from 'rfc6902'
import _ from 'lodash';
import { useFavouritesStore } from './favourites';
import { useLiveSportsStore } from './live_sports';

export const useLiveSportOverview = defineStore('liveSportOverview', {
	state: () => ({
		overview: null,
		mobile_dropdown_open: false,
		selected_competition_ids: {},
	}),
	getters: {
		overviewFixturesKeys(state) {
			if (!state.overview) return null;
			return Object.keys(state.overview);
		},
		overviewFixturesCount(state) {
			if (!state.overview) return null;
			return Object.keys(state.overview).length;
		},
		overviewFixtures(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.overview);
			let favourite_fixtures = [];
			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {

					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(Number(fixture_id))) {
						fixtures[fixture_id].favourite = true;
						favourite_fixtures.push(fixtures[fixture_id]);

						fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name + '-fav';

						delete fixtures[fixture_id];
					}
				}
			}

			fixtures = _.chain(fixtures).orderBy(['favourite', 'sort', 'competition_weight', 'competition_region_name'], ['asc', 'asc', 'desc', 'asc']).groupBy('competition_region_name').value();
			favourite_fixtures = _.groupBy(favourite_fixtures, 'competition_region_name');

			if (Object.keys(favourite_fixtures).length > 0) return {...favourite_fixtures, ...fixtures}

			return fixtures;
		},
		highlightFixtures(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.overview);
			let favourite_fixtures = [];

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {

					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(Number(fixture_id))) {
						fixtures[fixture_id].favourite = true;
						favourite_fixtures.push(fixtures[fixture_id]);

						fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name + '-fav';

						delete fixtures[fixture_id];
					}
				}
			}

			fixtures = _.chain(fixtures).orderBy(['sort', 'competition_weight', 'competition_region_name'], ['asc', 'desc', 'asc']).value();

			let grouped_fixtures = [];
			if (favourite_fixtures.length > 0) {
				grouped_fixtures = [...favourite_fixtures, ...fixtures];
			} else {
				grouped_fixtures = fixtures;
			}

			grouped_fixtures = _.take(grouped_fixtures, 15);

			return _.groupBy(grouped_fixtures, 'competition_region_name');
		},
		leftSidebarEventviewFixtures(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.overview);

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {
					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(Number(fixture_id))) {
						fixtures[fixture_id].favourite = true;
					}

				}
			}

			return _.chain(fixtures).orderBy(['sort', 'competition_weight', 'competition_region_name'], ['asc', 'desc', 'asc']).groupBy('competition_region_name').value();
		},
		dropDownEventViewFixtures(state) {
			const favouritesStore = useFavouritesStore();
			let fixtures = _.cloneDeep(state.overview);

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {
					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(Number(fixture_id))) {
						fixtures[fixture_id].favourite = true;
					}

				}
			}

			return _.orderBy(fixtures, ['sort', 'competition_weight', 'competition_region_name'], ['asc', 'desc', 'asc'])
		},
		getFirstFixture(state) {
			if (useLiveSportsStore().favouritesSelected) {
				return useFavouritesStore().firstFixtureOfSportOverview;
			}

			let first_fixture_competition = Object.keys(this.overviewFixtures)[0]
			return this.overviewFixtures[first_fixture_competition][0];
		},
		mobileDropdownOpen(state) {
			return this.mobile_dropdown_open;
		},
		selectedCompeititionIds(state) {
			return state.selected_competition_ids;
		}
	},
	actions: {
		setOverview(data) {
			this.overview = data;
		},
		updateOverview(data) {
			applyPatch(this.overview, data);
		},
		emptyLiveSportsOverview() {
			this.overview = null;
		},
		toggleMobileDropdown() {
			this.mobile_dropdown_open = !this.mobile_dropdown_open;
		},
		toggleCompetitionId(data) {
			// Check if i have configuration for sport and check if competition exists to remove it
			if (this.selected_competition_ids.hasOwnProperty(data.sport_id)) {
				if (this.selected_competition_ids[data.sport_id].includes(data.competition_id)) {
					_.remove(this.selected_competition_ids[data.sport_id], function(competition_id) { return competition_id == data.competition_id });
					return;
				}

				this.selected_competition_ids[data.sport_id].push(data.competition_id);
				return;
			}

			// Just create sport key and insert competition
			this.selected_competition_ids[data.sport_id] = [];
			this.selected_competition_ids[data.sport_id].push(data.competition_id);
		},
		emptySelectedCompetitionIds(sport_id) {
			if (this.selected_competition_ids.hasOwnProperty(sport_id)) {
				this.selected_competition_ids[sport_id] = [];
			}
		},
	},
})