<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';

const { t } = useI18n();
const registerStore = useRegisterStore();
</script>

<template>
	<div class="register-form-field">
		<label>{{ $t('account.register.referral_code') }}</label>
		<input class="form-input" :placeholder="$t('account.register.referral_code')" v-model="registerStore.register_input_fields.referral_code" type="text"
		name="full_name" required="required" />
	</div>
</template>

<style scoped>
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input { 
	padding: 10px 15px 10px 15px;
}
</style>
