<!-- eslint-disable -->

<script setup>
import { useMenuStore } from '@/stores/menus';
import { useGeneralStore } from '@/stores/general';
import { version } from '../../../package.json';

const menuStore = useMenuStore();
const generalStore = useGeneralStore();

const getUrl = (menu_child) => {
	if (!menu_child.click_url && !menu_child.static_page_url) return false
	if (menu_child.click_url == '#/promotions/all') return menu_child.click_url.replace('#', '').replace('/all', '');
	if (menu_child.click_url) return menu_child.click_url.replace('#', '');
	else if (menu_child.static_page_url) return menu_child.static_page_url;
}
</script>
<template>
	<footer>
		<div class="container-lg">
			<div class="footer-outer">
				<!-- footer column -->
				<template v-if="menuStore.getFooterMenus">
					<div class="footer-inner" v-for="menu in menuStore.getFooterMenus" :key="menu">
						<h6>{{ menu.name }}</h6>
						<div class="footer-menu ">
							<ul>
								<template v-for="menu_child in menu.children" :key="menu_child">
									<li v-if="menu_child.click_url == '/live-chat'" ><a href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});">{{ menu_child.name }}</a></li>
									<li v-else><a v-if="getUrl(menu_child)" :href="getUrl(menu_child)">{{ menu_child.name }}</a></li>
								</template>
							</ul>
						</div>
					</div>
				</template>
				<!-- end -->

			</div>

			<!-- footer copyright -->
			<template v-if="generalStore.getGeneral">
				<div class="desclaimer-bottom">
					<img style="margin-bottom: 20px; height: 50px !important;" :src="generalStore.getGeneral.footer_logo" />
					<p v-html="menuStore.getFooterDescription"></p>
					<div class="copyright-text">
						<p>© {{ generalStore.getGeneral.site_name }} 2023 Preview Build {{ version }} </p>
					</div>

				</div>
			</template>
			<!-- end -->

		</div>
	</footer>
</template>
<style scoped>
html[dir="rtl"] .footer-outer {
	padding-right: 40px;
}
</style>
