import { defineStore } from 'pinia';
import { applyPatch } from 'rfc6902'
import { useDatesStore } from './dates';
import { useFavouritesStore } from './favourites';
import _ from 'lodash';
import moment from 'moment';

export const useCompetitionsStore = defineStore('competitions', {
	state: () => ({
		competition_fixtures: {},
		selected_competition_id: null,
		top_competitions: [],
		top_competitions_sport: []
	}),
	getters: {
		competitionFixturesByDate(state) {
			const datesStore = useDatesStore();
			const favouritesStore = useFavouritesStore();

			let coupon_competitions = state.competition_fixtures;
			if (_.isEmpty(coupon_competitions)) return;

			let coupon_competitions_with_fixtures = [];
			for (const key in coupon_competitions) {
				if (Object.hasOwnProperty.call(coupon_competitions, key)) {
					let competition_fixtures = _.orderBy(coupon_competitions[key], ['live', 'start_datetime', 'participants.0'], ['desc', 'asc', 'asc']);

					for (const fixture_key in competition_fixtures) {
						if (Object.hasOwnProperty.call(competition_fixtures, fixture_key)) {
							competition_fixtures[fixture_key].date = datesStore.competitionFixtureDate(competition_fixtures[fixture_key].start_datetime);

							competition_fixtures[fixture_key].favourite = false;
							if (favouritesStore.favouriteFixtureIds.includes(competition_fixtures[fixture_key].id)) {
								competition_fixtures[fixture_key].favourite = true;
							}
						}
					}

					if (Object.keys(competition_fixtures).length > 0) {
						let first_fixture = Object.values(competition_fixtures)[0];

						let competition_resource = {
							competition_id: key,
							competition_name: first_fixture.competition_name,
							competition_weight: first_fixture.competition_weight,
							region_icon_url: first_fixture.region_icon_url,
							region_name: first_fixture.region_name,
							dates: _.groupBy(competition_fixtures, 'date')
						};

						coupon_competitions_with_fixtures.push(competition_resource);
					}
				}
			}

			coupon_competitions_with_fixtures = _.orderBy(coupon_competitions_with_fixtures, ['competition_weight', 'region_name'], ['desc', 'asc']);

			return coupon_competitions_with_fixtures;
		},
		todaysCompetitionFixtures: (state) => (datetime) => {
			const datesStore = useDatesStore();

			let start_of_day = datesStore.translateAndOffsetDatetime(datetime).startOf('day')
			let end_of_day = datesStore.translateAndOffsetDatetime(datetime).endOf('day')

			let first_key = Object.keys(state.competition_fixtures)[0];
			let fixtures = _.filter(state.competition_fixtures[first_key],  (fixture) => {
				return moment.utc(fixture.start_datetime) > moment.utc()
				&& moment.utc(fixture.start_datetime).isAfter(start_of_day)
				&& moment.utc(fixture.start_datetime).isBefore(end_of_day)
			});

			return _.orderBy(fixtures, ['start_datetime'], ['asc']);


		},
		selectedCompetitionId(state) {
			return state.selected_competition_id;
		},
		topCompetitions(state) {
			return _.orderBy(state.top_competitions, ['sort', 'weight'], ['asc', 'desc']);
		},
		topCompetitionsSport(state) {
			return _.orderBy(state.top_competitions_sport, ['sort', 'weight'], ['asc', 'desc']);
		},
		isAllFixturesOutrights: (state) => (competition_id) => {
			let competition = state.competition_fixtures[competition_id];
			if (!competition) return false;

			let regular_fixtures = _.find(competition, {'outright': false});
			if (regular_fixtures) return false;

			return true;
		}
	},
	actions: {
		setCompetitionFixtures(data) {
			this.competition_fixtures[data.competition_id] = data.fixtures;
		},
		updateCompetitionFixture(data) {
			applyPatch(this.competition_fixtures[data.competition_id][data.fixture_id], data.patch);
		},
		deleteCompetitionFixture(data) {
			delete this.competition_fixtures[data.competition_id][data.fixture_id];
		},
		setSelectedCompetitionId(data) {
			this.selected_competition_id = data;
		},
		emptyCompetitions() {
			this.competition_fixtures = {};
			// this.selected_competition_id = null;
		},
		fetchTopCompetitions() {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/competitions/featured', { withCredentials: false }).then((response) => {
				this.top_competitions = response.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchTopCompetitionsSport(payload) {
			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/sport/' + payload + '/competitions/featured', { withCredentials: false }).then((response) => {
				this.top_competitions_sport = response.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
	},
})