<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';
import { useCountriesStore } from '../../stores/countries';
import { ref, onMounted, watch } from 'vue';
import _ from 'lodash';

const { t } = useI18n();
const registerStore = useRegisterStore();
const countriesStore = useCountriesStore();
const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';
const enabled_fields = registerStore.getEnabledRegistrationFields;
const country = ref(null);

let default_country_id = null;

if (is_country_ghana) {
	if (enabled_fields) {
		const country_field = enabled_fields.find(field => field.slug == 'country_id');
		if (country_field) {
			default_country_id = country_field.default_value;
			registerStore.register_input_fields.country_id = country_field.default_value;
		}
	}
}

watch(() => registerStore.register_input_fields.country_id, (newValue, oldValue) => {
	if (newValue != null && registerStore.register_input_fields.mobile_country_id == null) {
		registerStore.register_input_fields.mobile_country_id = newValue;
	}
});

</script>

<template>
<div class="register-form-field">
	<label>{{ $t('account.register.country_of_residence') }} </label>
	<select name="" id="address-country" :style="{ cursor: is_country_ghana ? 'not-allowed' : 'pointer' }" class="form-input form-control" required v-model="registerStore.register_input_fields.country_id" :disabled=is_country_ghana>
		<option :value="null" disabled>{{ $t('account.register.choose_country') }}</option>
		<option v-for="country in countriesStore.getCountries" :key="country.id" :value="country.id">{{ country.name }}</option>
	</select>
	<div class="register-form-list" v-if="registerStore.register_errors.country_id.error && !registerStore.register_input_fields.country_id" >
			<div class="password-meter">
				<div class="password-meter-bar">
					<div class="password-meter-bar-inn"></div>
				</div>
			</div>
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.country_id.error == 'error_select_country'">
					{{ $t('account.register.error_select_country') }}
				</span>
				<span v-else-if="registerStore.register_errors.hasOwnProperty('country_id')">
					{{ registerStore.register_errors.country_id.error }}
				</span>
			</p>
	</div>
</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form select {
	background-position: 5%;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input {
	padding: 10px 15px 10px 15px;
}
</style>
