import { defineStore } from 'pinia';
import SharedBetslipError from '@/components/modals/SharedBetslipError.vue'
import { openModal } from "@kolirt/vue-modal";

export const useSharedBetslipsStore = defineStore('shared_betslips', {
	state: () => ({
		//
	}),
	getters: {
		//
	},
	actions: {
		storeSharedBetslip(bets) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/betslip/share/store', { bets: bets }).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		fetchSharedBetslip(code) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/betslip/share/fetch', { code: code }).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		deleteSharedBetslip(code, need_redirect) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/betslip/share/delete', { code: code } ).then((response) => {

				if (need_redirect) openModal(SharedBetslipError, {delete: true, need_redirect: need_redirect}).catch(() =>{});
				else openModal(SharedBetslipError, {delete: true}).catch(() =>{});

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		}
	}
});
