<script setup>
import { onMounted, ref } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import { useAccountDetailsStore } from '@/stores/account_details'

const customerStore = useCustomerStore();
const accountDetailsStore = useAccountDetailsStore();
const betslip_changes = ref('');


const toggleBetslipChangesPreferences = (betslip_changes_option) => {
	const payload = { betslip_changes: 'always_ask'};
	if (betslip_changes.value != betslip_changes_option) payload.betslip_changes = betslip_changes_option;

	accountDetailsStore.updateCustomerOdds(payload).then( () => {
		betslip_changes.value = payload.betslip_changes
	})
}

onMounted(() => {
	if (customerStore.isLoggedIn) betslip_changes.value = customerStore.getCustomer.customer_settings.betslip_changes;
	else betslip_changes.value = 'always_ask'
})

</script>

<template>
    <div class="content-main">
        <div class="title-main">
            <img src="@/assets/images/setting-ico.svg" alt="Lang Icon">
            <h3>{{ $t('general.setting') }}</h3>
        </div>
        
        <div class="radio-main-outer" v-if="customerStore.isLoggedIn">
            <div class="radio-main-inner">
                <form>
                    <div  class="switch-outer">
                        <label class="switch" @click="toggleBetslipChangesPreferences('accept_any_odds')">
                            <input :checked="betslip_changes == 'accept_any_odds'" type="checkbox">
                            <span class="switch-slider round"></span>
                        </label>
                    </div>
                </form>
                <p>{{ $t('account.account_preferences.accept_any_odds') }}</p>
            </div>

            <div class="radio-main-inner" v-if="customerStore.isLoggedIn">
                <form>
                    <div  class="switch-outer">
                        <label class="switch" @click="toggleBetslipChangesPreferences('accept_higher_odds')">
                            <input :checked="betslip_changes == 'accept_higher_odds'" type="checkbox">
                            <span class="switch-slider round"></span>
                        </label>
                    </div>
                </form>
                <p>{{ $t('account.account_preferences.accept_higher_odds') }}</p>
            </div>

        </div>
    </div>
</template>