<script setup>
/* eslint-disable */
import { onMounted, computed, ref } from "vue";
import { useMarketsStore } from '@/stores/markets';
import { useLiveSportsStore } from '@/stores/live_sports';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import LiveSportOverviewCompetition from '@/components/live_sports/LiveSportOverviewCompetition.vue';

const marketsStore = useMarketsStore();
const liveSportsStore = useLiveSportsStore();

const show_primary_overview_market = ref(false);
const show_secondary_overview_market = ref(false);

const primary_overview_market = ref(null);
const secondary_overview_market = ref(null);
const competitions_closed = ref(false);

const svg_data = ref(commonSvgs);

const props = defineProps({
	sport_overview: {
		type: Object,
		required: true,
	},
});

const selectPrimaryOverviewMarket = (overview_market) => {
	primary_overview_market.value = overview_market;
	show_primary_overview_market.value = false;
}

const selectSecondaryOverviewMarket = (overview_market) => {
	secondary_overview_market.value = overview_market;
	show_secondary_overview_market.value = false;
}

const closePrimaryOverviewMarketSelector = () => {
	show_primary_overview_market.value = false;
}

const closeSecondaryOverviewMarketSelector = () => {
	show_secondary_overview_market.value = false;
}

onMounted(() => {
	let sport_overview_market = marketsStore.sportOverviewMarkets(firstFixture.value.sport_id);
	primary_overview_market.value = sport_overview_market[0];

	if (sport_overview_market.length > 1) {
		secondary_overview_market.value = sport_overview_market[1];
	} else {
		secondary_overview_market.value = sport_overview_market[0];
	}
});

const firstFixture = computed(() => {
	let competition_keys = Object.keys(props.sport_overview);
	let first_fixture = props.sport_overview[competition_keys[0]][0];

	return first_fixture;
});

const fixturesCount = computed(() => {
	let fixtures_count = 0;
	for (const competition_name in props.sport_overview) {
		const element = props.sport_overview[competition_name];
		fixtures_count += element.length
	}

	return fixtures_count;
});

</script>
<template>

	<div class="live-bet-dashboard round-game-liveSport round-games-main ">
			<div class="bet-dashboard-top">
				<div class="dashboard-info-label bet-tab-title">
					<div class="sport-title">
						<img :src="liveSportsStore.liveSportGreenIcon(firstFixture.sport_id)" alt="">
						<h6>{{ firstFixture.sport_name }} {{ fixturesCount ? '(' + fixturesCount + ')' : '' }}</h6>
					</div>

					<div class="live-badge" v-html="svg_data.live_badge"></div>
				</div>
				<div class="mobile-info-label">
					<img :src="liveSportsStore.liveSportGreenIcon(firstFixture.sport_id)" alt="">
					<h3>{{ firstFixture.sport_name }} {{ fixturesCount ? '(' + fixturesCount + ')' : '' }}</h3>
				</div>

				<div class="dashboard-info-label dropdown-option-top" v-if="primary_overview_market">
					<div class="select-option">
						<dl class="drop" :class="show_primary_overview_market ? 'active' : ''">
							<span class="tabIco">
								<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
									<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
										<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
									</g>
								</svg>
							</span>
							<dt><button class="tablinks" @click="show_primary_overview_market = !show_primary_overview_market"  v-click-outside-element="closePrimaryOverviewMarketSelector">{{ primary_overview_market.name.toUpperCase() }}</button>
							</dt>
							<dd>
								<ul>
									<li v-for="overview_market in marketsStore.sportOverviewMarkets(firstFixture.sport_id)" :key="overview_market.slug" @click="selectPrimaryOverviewMarket(overview_market)" :class="primary_overview_market.slug == overview_market.slug ? 'active' : ''">
										<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
									</li>
								</ul>
							</dd>
						</dl>
					</div>
				</div>

				<div class="dashboard-info-label dropdown-option-top dropdown-option-top-2" v-if="secondary_overview_market">
					<div class="select-option">
						<dl class="drop" :class="show_secondary_overview_market ? 'active' : ''">
							<span class="tabIco">
								<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
									<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
										<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
									</g>
								</svg>
							</span>
							<dt><button class="tablinks" @click="show_secondary_overview_market = !show_secondary_overview_market"  v-click-outside-element="closeSecondaryOverviewMarketSelector">{{ secondary_overview_market.name.toUpperCase() }}</button>
							</dt>
							<dd>
								<ul>
									<li v-for="overview_market in marketsStore.sportOverviewMarkets(firstFixture.sport_id)" :key="overview_market.slug" @click="selectSecondaryOverviewMarket(overview_market)" :class="secondary_overview_market.slug == overview_market.slug ? 'active' : ''">
										<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
									</li>
								</ul>
							</dd>
						</dl>
					</div>
				</div>
				<span class="toggle-markets">
					<div @click="competitions_closed = !competitions_closed" class="markets-toggle-fill">
						<svg v-if="!competitions_closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_close.svg#markets_close"></use>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_open.svg#markets_open"></use>
						</svg>
					</div>
				</span>

			</div>
			<div class="live-tab-info">
				<div id="overview" class=" overview-live">
					<LiveSportOverviewCompetition v-for="(competition, index) in sport_overview" :key="index"
						:competition="competition"
						:competitions_closed="competitions_closed"
						:primary_overview_market="primary_overview_market"
						:secondary_overview_market="secondary_overview_market"
					/>
				</div>
			</div>
	</div>

</template>
<style scoped>

.dropdown-option-top {
	display: block !important;
}

@media screen and (min-width: 320px) and (max-width: 1600px) {
	.dropdown-option-top {
		display: block !important;
	}

	.dropdown-option-top-2 {
		display: none !important;
	}

	.live-bet-dashboard .live-tab-info .live-info-outer .live-info-inner .match-goal-top {
		display: none;
	}

}


@media screen and (min-width: 320px) and (max-width: 767px) {
	.sports-main .live_Sports-main-outer .live-sports-center .live-bet-dashboard .bet-dashboard-top .mobile-info-label {
		display: grid;
		grid-template-columns: 12% 88%;
		grid-gap: 5%;
	}
}

.overview-live {
	overflow-x: hidden;
}
</style>
