import { defineStore } from 'pinia';
import { toRaw } from 'vue'
import _ from 'lodash';
import { useCustomerStore } from './customer';
import currencySettings from '../helpers/currencies.json';
// commit
export const useCurrenciesStore = defineStore('currencies', {
	state: () => ({
		currencies: null
	}),
	getters: {
		getCurrencies(state){
			return state.currencies
		},
		getCurrencyCode(state) {
			const customerStore = useCustomerStore();
			if (customerStore.isLoggedIn) {
				return customerStore.getCustomer.currency.code
			}

			let default_currency = _.find(state.currencies, { 'default': true });

			return default_currency.code;
		},
		getCurrencySymbol(state) {
			const customerStore = useCustomerStore();
			if (customerStore.isLoggedIn) {
				return customerStore.getCustomer.currency.symbol
			}

			let default_currency = _.find(state.currencies, { 'default': true });

			return default_currency.symbol;
		},
		format_currency: (state) => (amount, currency_code = null) => {
			if (isNaN(amount) || isNaN(parseFloat(amount))) return amount;

			if (currency_code == null) {
				currency_code = state.getCurrencyCode;
			}

			// Get currency from currency code or from logged in user
			let currency = currencySettings[currency_code];

			if (!currency_code) {
				var default_currency = _.find(toRaw(state.currencies), { 'default': true });

				if (_.isEmpty(default_currency)) return amount;

				currency = currencySettings[default_currency.code];
			}

			let format = '';
			if (currency.symbol_position == 'left') {
				format += currency.symbol;

				if (currency.space_between) format += ' ';
			}

			format += Number(amount).toFixed(currency.decimals)
				.replace(/\B(?=(\d{3})+(?!\d))/g, currency.thousands_separator)
				.replace('.', currency.decimal_separator);

			if (currency.symbol_position == 'right') {
				if (currency.space_between) format += ' ';

				format += currency.symbol;
			}

			return format;
		},
	},
	actions: {
		setCurrencies(data) {
			this.currencies = data;
		}
	},
})