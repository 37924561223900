<script setup>
/* eslint-disable */
import { onMounted, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useFixtureStore } from '@/stores/fixture';
import { useMarketsStore } from '@/stores/markets';
import FixtureOverviewOdd from './FixtureOverviewOdd.vue';

import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svg_data = ref(commonSvgs);

const router = useRouter();

const liveSportsStore = useLiveSportsStore();
const fixtureStore = useFixtureStore();
const marketsStore = useMarketsStore();

const primary_overview_market = ref(marketsStore.sportOverviewMarkets(props.fixture.sport_id)[0]);

const props = defineProps({
	fixture: {
		type: Object,
		required: true,
	},
});

onMounted(() => {
	primary_overview_market.value = marketsStore.sportOverviewMarkets(props.fixture.sport_id)[0];
});

const goToFixture = () => {
	router.push( { name: 'live-sports-eventview', params: { fixture_id: props.fixture.id } } );
}

const primaryOverviewMarketOdds = computed(() => {
	if (!props.fixture.overview_markets.hasOwnProperty(primary_overview_market.value.slug)) return [];

	return props.fixture.overview_markets[primary_overview_market.value.slug];
})

</script>
<template>
<div class="live_score_Box carousel__item">
	<div class="league-title">
		<div class="title" @click="goToFixture">
			<img :src="liveSportsStore.liveSportWhiteIcon(props.fixture.sport_id)" alt="">
			<h6>{{ props.fixture.competition_name }}</h6>
		</div>
	</div>
	<!-- score panel start-->
	<div class="league-score" @click="goToFixture">
		<div class="team-playing-left team-playing" :class="props.fixture.statistics?.possession && props.fixture.statistics?.possession == 1 ? 'service-active' : ''">
			<!-- <img src="@/assets/images/team-left.png" alt=""> -->
			<h6>{{ props.fixture.participants ? props.fixture.participants[0]: '' }}</h6>
		</div>
		<div class="team-playing-score">
			<div class="current-score">
				<h4>{{ props.fixture.statistics?.p1_score }}</h4>
				<h4>:</h4>
				<h4>{{ props.fixture.statistics?.p2_score }}</h4>
			</div>
			<div class="match-live-status" v-if="props.fixture.live">
				<div class="live-badge" v-html="svg_data.live_badge"></div>
				<div class="playing-time" v-if="liveSportsStore.hasFixtureClock(props.fixture.sport_id)">
					<p>{{ fixtureStore.fixtureClockTime(props.fixture) }} {{ fixtureStore.fixtureState(fixture) }}
						<span></span>
					</p>
				</div>
				<div class="playing-time" v-else>
					{{ fixtureStore.fixtureState(props.fixture) }}
					<span></span>
				</div>
			</div>
		</div>
		<div class="team-playing-right team-playing" :class="props.fixture.statistics?.possession && props.fixture.statistics?.possession == 2 ? 'service-active' : ''">
			<!-- <img src="@/assets/images/team-right.png" alt=""> -->
			<h6>{{ props.fixture.participants ? props.fixture.participants[1] : '' }}</h6>
		</div>
	</div>
	<!-- score panel end-->

	<!-- betting live info start-->
	<div class="betting_info">
		<FixtureOverviewOdd
			:overview_market_header_name="primary_overview_market.odd_names[0]"
			:overview_odd="primaryOverviewMarketOdds[0]"
			:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
			:fixture_id="props.fixture.id"
		/>

		<FixtureOverviewOdd v-if="primary_overview_market.three_way"
			:overview_market_header_name="primary_overview_market.odd_names[1]"
			:overview_odd="primaryOverviewMarketOdds[1]"
			:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
			:fixture_id="props.fixture.id"
		/>

		<FixtureOverviewOdd
			:overview_market_header_name="primary_overview_market.odd_names[2]"
			:overview_odd="primary_overview_market.three_way ? primaryOverviewMarketOdds[2] : primaryOverviewMarketOdds[1]"
			:needs_indicator="marketsStore.overviewMarketNeedsIndicator(primary_overview_market)"
			:fixture_id="props.fixture.id"
		/>
	</div>
</div>
</template>