<!-- eslint-disable -->
<script setup>
import { ref } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import { useCurrenciesStore } from '@/stores/currencies';
import { useBonusStore } from '@/stores/bonus';
import { useLoginStore } from '@/stores/login';
import { openModal } from '@kolirt/vue-modal';
import headerIcons from '@/assets/svgs/Header.json';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import VerificationEmail from '../modals/VerificationEmail.vue';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';

const customerStore = useCustomerStore();
const currenciesStore = useCurrenciesStore();
const bonusStore = useBonusStore();
const loginStore = useLoginStore();
const { t } = useI18n();
const header_icons = headerIcons;
const svgs_data = commonSvgs;
const tab_active = ref('account');
const loading = ref(false);
const active_bonus = ref(null);
const refresh_balance = ref(false);
const active_bonus_id = ref(null);

const claimBonus = (bonus_id) => {
	loading.value = true;
	let payload = { bonus_id: bonus_id };

	if (!customerStore.hasVerifiedEmail) {
		openModal(VerificationEmail);
		loading.value = false;
		return;
	}

	bonusStore.claimManualBonus(payload).then(() => {
		bonusStore.fetchOffers();
		fireSwal('success', t('general.claim_bonus'));
		loading.value = false;
	}).catch((error) => {
		fireSwal('error', error.data.message);
		loading.value = false;
	});
}

const refreshBalance = () => {
	refresh_balance.value = true

	customerStore.fetchBalance().then(() => {
		refresh_balance.value = false;
	}).catch((error) => {
		refresh_balance.value = false;
	})
}

const cancelBonus = (bonus_id) => {
	loading.value = true;
	let payload = { bonus_id: bonus_id };

	active_bonus_id.value = null;

	bonusStore.cancelCustomerBonus(payload.bonus_id).then(() => {
		fireSwal('success', t('general.cancel_bonus'));
		loading.value = false;
		bonusStore.fetchOffers();
	}).catch((error) => {
		fireSwal('error', error.data.message);
		loading.value = false;
	});
}

const cancelBonusVerification = (id) => {
	active_bonus_id.value = id
	confirmCancelSwal();
}

const logout = () => {
	loginStore.logout()
};

const toggleBalanceVisibility = () => {
	if (customerStore.balance_visibility == true) customerStore.balance_visibility = false;
	else customerStore.balance_visibility = true;
};

const fireSwal = (alert_type, alert_message) => {
	Swal.fire({
		text: alert_message,
		icon: alert_type,
		confirmButtonText: alert_type == 'success' ? t('account.register.close') : t('general.try_again'),
	});
}

const confirmCancelSwal = () => {
	Swal.fire({
		text: t('general.cancel_bonus_text'),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: t('general.yes'),
		cancelButtonText: t('general.no'),
	}).then((result) => {
		if (result.isConfirmed) {
			cancelBonus(active_bonus_id.value);
		}
	});
}
</script>
<template>
	<div class="user-dropdown-info">
		<div class="user-amount-info">
			<div class="user-amount-inner">
				<span>{{ $t('general.withdrawable') }}</span>
				<h6 v-if="customerStore.balance_visibility == true">{{ currenciesStore.format_currency(customerStore.getCustomer.balance.withdrawable, customerStore.getCustomer.currency.code) }}</h6>
				<h6 v-else>********</h6>
			</div>
			<div class="user-amount-inner">
				<span>{{ $t('general.bet_credits') }}</span>
				<h6 v-if="customerStore.balance_visibility == true">{{ currenciesStore.format_currency(customerStore.getCustomer.balance.bonus_balance, customerStore.getCustomer.currency.code) }}</h6>
				<h6 v-else>********</h6>
			</div>
			<div class="balance-container">
				<span class="mobile-only" v-if="customerStore.balance_visibility == true">
				<svg @click="toggleBalanceVisibility()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
					<path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
					<circle cx="12" cy="12" r="3"></circle></svg>
				</span>
				<span class="mobile-only" v-else>
					<svg  @click="toggleBalanceVisibility()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
						<g id="Icon_feather-eye-off" data-name="Icon feather-eye-off" transform="translate(-0.793 2.207)">
							<path id="Path_266" data-name="Path 266" fill="none" stroke="#FFFFFF" d="M12.28,14.846A6.42,6.42,0,0,1,8.5,16.153c-4.455,0-7-5.077-7-5.077A11.717,11.717,0,0,1,4.72,7.307M7.164,6.152A5.819,5.819,0,0,1,8.5,6c4.455,0,7,5.077,7,5.077A11.738,11.738,0,0,1,14.125,13.1m-4.276-.679a1.907,1.907,0,1,1-2.7-2.691" transform="translate(0 -2.577)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
							<path id="Path_267" data-name="Path 267" d="M1.5,1.5l14,14" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
						</g>
					</svg>
				</span>
			<a @click="refreshBalance" class="dropdown-eye-icon">
				<a>
					<svg :class="{ 'rotate-svg': refresh_balance }" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 30 30"
						style="fill:#FFFFFF;">
						<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
					</svg>
				</a>
			</a>
			</div>
			<div class="user-amount-btn">
				<router-link to="/account/bank/deposits" class="btn btn-secondary">{{ $t('general.deposit') }}</router-link>
			</div>
		</div>

		<div class="user-dropdown-tabs offer-tab-active">
			<div class="tab">
				<button class="tablinks" :class="tab_active=='account' ? 'active' : ''" @click=" tab_active = 'account'">{{ $t('general.my_account') }}</button>
				<button class="tablinks" :class="tab_active=='offers' ? 'active' : ''" @click=" tab_active = 'offers'">{{ $t('general.my_offers') }}</button>
			</div>

			<!-- my account content area -->
			<div class="tab-content" :class="tab_active == 'account'? 'active': ''" >
				<div class="user-tab-content">
					<div class="user-tab-inner">
						<div class="tab-inner-icon">
							<div v-html="header_icons.account"></div>
						</div>
						<router-link to="/account/account-details/preferences">
							<h6>{{ $t('general.account_preferences') }}</h6>
						</router-link>
					</div>

					<div class="user-tab-inner">
						<div class="tab-inner-icon">
							<div v-html="header_icons.bank"></div>
						</div>
						<router-link to="/account/bank/deposits">
							<h6>{{ $t('general.bank') }}</h6>
						</router-link>
					</div>

					<div class="user-tab-inner">
						<div class="tab-inner-icon">
							<div v-html="header_icons.history"></div>
						</div>
						<router-link to="/account/history/settled-bets">
							<h6>{{ $t('general.history') }}</h6>
						</router-link>
					</div>

					<div class="user-tab-inner">
						<div class="tab-inner-icon">
							<div v-html="header_icons.player_protech"></div>
						</div>
						<router-link to="/account/player-protection/limits/login-limit">
							<h6>{{$t('account.customer_protection.customer_protection_title')}}</h6>
						</router-link>
					</div>

					<div class="user-tab-inner">
						<div class="tab-inner-icon" v-if="customerStore.getCustomer">
							<span class="notification-num" v-if="customerStore.getCustomer.unread_messages_count > 0">{{ customerStore.getCustomer.unread_messages_count }}</span>
							<div v-html="header_icons.message"></div>
						</div>
						<router-link to="/account/messages">
							<h6>{{ $t('general.messages') }}</h6>
						</router-link>
					</div>

					<div class="user-tab-inner">
						<div class="tab-inner-icon">
							<span class="notification-num" v-if="customerStore.unclaimed_bonuses_length > 0">{{ customerStore.unclaimed_bonuses_length }}</span>
							<div v-html="header_icons.bonus"></div>
						</div>
						<router-link to="/account/bonus/active-bonuses">
							<h6>{{ $t('general.bonus') }}</h6>
						</router-link>
					</div>

				</div>
			</div>
			<!-- end -->
			<!-- my offer content area -->
			<div class="tab-content" :class="tab_active=='offers'? 'active': ''">
				<div class="my-offer-outer">
					<div class="offers-accordion">
						<div class="offers-empty" v-if="(!customerStore.availableBonuses.length > 0 && !customerStore.activeBonuses.length > 0)">
							<p>{{ $t('general.no_available_bonus') }}</p>
						</div>

						<div class="offers-accordion-inner" v-else v-for="(available_bonus, index) in customerStore.availableBonuses" :key="index" :class="active_bonus==index+'index'? 'active':''" @click="active_bonus==index+'index'? active_bonus = '': active_bonus = index+'index'">
							<div class="offers-top-header">
								<h3>{{ available_bonus.name }}</h3>
								<span v-html="svgs_data.dropdown_icon"></span>
							</div>
							<div class="offers-tab-content">
								<div class="offers-tab-main">
									<div class="offer-content-inner">
										<h4>{{ $t('account.affiliate.product') }}:</h4>
										<p>{{ available_bonus.products }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.minimum_deposit_amount') }}:</h4>
										<p>{{ currenciesStore.format_currency(available_bonus.min_deposit_amount, customerStore.customer.currency.code) }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.maximum_bonus_amount') }}:</h4>
										<p>{{ currenciesStore.format_currency(available_bonus.max_bonus_amount, customerStore.customer.currency.code) }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.deposit_bonus_percentage') }}:</h4>
										<p>{{ available_bonus.bonus_deposit_percentage }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.rollover_factor_deposit') }}:</h4>
										<p>{{ available_bonus.rollover_factor_deposit }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.rollover_factor_bonus') }}:</h4>
										<p>{{ available_bonus.rollover_factor_bonus }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.expiration_days') }}:</h4>
										<p>{{ available_bonus.expiration_days }}</p>
									</div>
								</div>
								<div class="user-amount-btn" v-if="available_bonus.is_deposit_bonus">
									<router-link to="/account/bank/deposits" class="btn btn-secondary" >{{ $t('general.deposit') }}</router-link>
								</div>
								<div class="user-amount-btn" v-else>
									<button type="button" class="btn btn-secondary" v-if="!loading" @click="claimBonus(available_bonus.id)" style="cursor: pointer;">{{ $t('general.claim') }}</button>
									<button class="btn btn-secondary" type="button" v-else-if="loading"><div class="loader-main"></div></button>
								</div>
							</div>
						</div>

						<div class="offers-accordion-inner" v-show="customerStore.activeBonuses.length > 0" v-for="(bonus, index) in customerStore.activeBonuses" :key="index" :class="active_bonus==index+'index'? 'active':''" @click="active_bonus==index+'index'? active_bonus = '': active_bonus = index+'index'">
							<div class="offers-top-header">
								<h3>{{bonus.name}}</h3>
								<span v-html="svgs_data.dropdown_icon"></span>
							</div>
							<div class="offers-top-header">
								<h3>{{ $t('general.progress') }}:</h3>
								<div class="main-progress-bar">
									<progress :value="bonus.stake_current * 100 / bonus.stake_target" max="100" class="main-progress-bar"></progress>
								</div>
							</div>
							<div class="offers-tab-content">
								<div class="offers-tab-main">
									<div class="offer-content-inner">
										<h4>{{ $t('general.bonus_description') }}:</h4>
										<p>{{ bonus.short_description }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.bonus_amount') }}:</h4>
										<p>{{ currenciesStore.format_currency(bonus.bonus_amount, customerStore.customer.currency.code) }}</p>
									</div>
									<div class="offer-content-inner">
										<h4>{{ $t('general.expires_at') }}:</h4>
										<p>{{ bonus.expires_at }}</p>
									</div>
								</div>
								<div class="user-amount-btn">
									<button type="button" class="btn btn-secondary" v-if="!loading" @click="cancelBonusVerification(bonus.id)" style="cursor: pointer;">{{ $t('general.cancel') }}</button>
									<button class="btn btn-secondary" type="button" v-else><div class="loader-main"></div></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end -->
		</div>
		<div class="user-logout-btn">
			<a href="#" @click="logout">{{ $t('general.logout') }}</a>
		</div>
	</div>
</template>
<style scoped>

.balance-container {
	display: grid;
	/* grid-template-columns: 66px 93px 42px; */
	/* grid-template-columns: 66px 74px 78px; */
	grid-template-columns: 40px 27px;
	align-items: center;

}
@media screen and (max-width: 400px) {
	.balance-container {
		margin-left: 50px;
	}
	html[dir="rtl"] .balance-container {
		margin-left: unset;
		margin-right: 50px;
	}
}

@media screen and (min-width: 401px) and (max-width: 500px) {
	.balance-container {
		margin-left: 80px;
	}
	html[dir="rtl"] .balance-container {
		margin-left: unset;
		margin-right: 80px;
	}
}
@media screen and (min-width: 501px) and (max-width: 600px) {
	.balance-container {
		margin-left: 100px;
	}
	html[dir="rtl"] .balance-container {
		margin-left: unset;
		margin-right: 100px;
	}
}

@media screen and (min-width: 601px) and (max-width: 700px) {
	.balance-container {
		margin-left: 130px;
	}
	html[dir="rtl"] .balance-container {
		margin-left: unset;
		margin-right: 130px;
	}
}
@media screen and (min-width: 701px) and (max-width: 767px) {
	.balance-container {
		margin-left: 150px;
	}
	html[dir="rtl"] .balance-container {
		margin-left: unset;
		margin-right: 150px;
	}
}
@media screen and (min-width: 768px) and (max-width: 3000px) {
	span.mobile-only {
		display:none;
	}
	.balance-container {
		grid-template-columns: auto;
		margin-left: 50px;
	}
}
@media screen and (max-width: 767px) {
	.popup-alert {
		position: fixed;
		top: 50%;
		left: 0% !important;
		width: 100%;
		z-index: 999;
	}
}

.popup-alert {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 999;
}

header.Logged-in .header-inner .login-info .user-profile-top.active .user-dropdown-info .user-amount-info {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr !important;

}

/* .dropdown-eye-icon {
	margin-top: 10px;
	padding-left: 17px;
} */
/*
html[dir="rtl"] .dropdown-eye-icon {
	margin-top: 10px;
	padding-left: unset;
	padding-right: 17px !important;
} */

@media screen and (min-width: 510px) and (max-width: 767px) {
	/* .dropdown-eye-icon {
		padding-left: 170px;
	} */

	/* html[dir="rtl"] .dropdown-eye-icon {
		padding-left: unset;
		padding-right: 170px !important;
	} */
}

@media screen and (min-width: 320px) and (max-width: 509px) {
	/* .dropdown-eye-icon {
		padding-left: 100px;
	}

	html[dir="rtl"] .dropdown-eye-icon {
		padding-left: unset;
		padding-right: 100px !important;
	} */
}

@media screen and (min-width: 768px) {
	/* .dropdown-eye-icon {
		padding-left: 50px;
	}

	html[dir="rtl"] .dropdown-eye-icon {
		padding-left: unset;
		padding-right: 50px !important;
	} */
}

@media screen and (min-width: 768px) {

	.user-dropdown-info .user-amount-info .user-amount-btn a {
		width: 220px !important;
	}

	.user-dropdown-info .user-amount-info .user-amount-btn {
		grid-column-start: 3;
		grid-column-end: auto;

	}
}

.rotate svg{
	transform: rotate(180deg);
	transition: transform 0.3s ease;
}

.buttons-flex {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: unset;
}

@media screen and (max-width: 380px) {
	.buttons-flex {
		display: flex;
		flex-wrap: wrap;
		justify-content: unset;
	}
}

@media screen and (max-height: 485px) {
    .user-dropdown-info {
        max-height: 250px; /* Adjust max height for even smaller screens */
		overflow-y: scroll;
    }
}
</style>