import { defineStore } from 'pinia';

export const useAffiliatesStore = defineStore('affiliates', {
	actions: {
		getLandingPage(payload) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/affiliates', { headers: { 'Accept': 'application/json' }, params: payload }).then((response) => {
				// Set cookies
				if (payload.btag) {
					this.setCookie('af_btag', payload.btag, 60);
				}

				if (payload.link) {
					this.setCookie('af_link', payload.link, 60);
				}

				if (payload.banner) {
					this.setCookie('af_banner', payload.banner, 60);
				}

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},

		setCookie(name, value, minutes) {
			const expires = new Date();
			expires.setTime(expires.getTime() + (minutes * 60 * 1000));
			document.cookie = name + '=' + encodeURIComponent(value) + ';expires=' + expires.toUTCString() + ';path=/';
		},
	}
})