import { defineStore } from 'pinia';
import _ from 'lodash';
import { useFavouritesStore } from './favourites';

export const useSportsbookSearchStore = defineStore('sportsbookSearch', {
	state: () => ({
		search_term: '',
		fixtures: []
	}),
	getters: {
		competitions(state) {
			let competitions = [];
			let fixtures_grouped_by_competition  = _.groupBy(state.fixtures, 'competition_id');
			for (const competition_id in fixtures_grouped_by_competition) {
				if (Object.hasOwnProperty.call(fixtures_grouped_by_competition, competition_id)) {
					const fixtures = fixtures_grouped_by_competition[competition_id];

					let competition = {
						id: fixtures[0].competition_id,
						name: fixtures[0].competition_name,
						weight: fixtures[0].competition_weight,
						sport_name: fixtures[0].sport_name,
						sport_id: fixtures[0].sport_id,
						region_name: fixtures[0].region_name,
						region_id: fixtures[0].region_id,
						region_icon_url: fixtures[0].region_icon_url,
						fixtures_count: fixtures.length
					}

					competitions.push(competition);
				}
			}

			return _.orderBy(competitions, ['weight', 'region_name'], ['desc', 'asc']);
		},
		overviewFixtures(state) {
			const favouritesStore = useFavouritesStore();

			let fixtures = _.cloneDeep(state.fixtures);
			let favourite_fixtures = [];
			let regular_fixtures = [];
			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {

					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;
					fixtures[fixture_id]['sport_competition_region_name'] = fixtures[fixture_id].sport_name + ' - ' + fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;

					if (favouritesStore.favouriteFixtureIds.includes(Number(fixtures[fixture_id].id))) {
						fixtures[fixture_id].favourite = true;
						favourite_fixtures.push(fixtures[fixture_id]);
						fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name + '-fav';
						fixtures[fixture_id]['sport_competition_region_name'] = fixtures[fixture_id].sport_name + ' - ' + fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name + '-fav';
					} else {
						regular_fixtures.push(fixtures[fixture_id]);
					}
				}
			}

			regular_fixtures = _.chain(regular_fixtures).orderBy(['favourite', 'sort', 'competition_weight', 'competition_region_name'], ['asc', 'asc', 'desc', 'asc']).groupBy('sport_competition_region_name').value();
			favourite_fixtures = _.groupBy(favourite_fixtures, 'sport_competition_region_name');

			if (Object.keys(favourite_fixtures).length > 0) return {...favourite_fixtures, ...regular_fixtures}

			return regular_fixtures;
		},
	},
	actions: {
		search() {
			if (this.search_term.length >= 3) {
				global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/search?term=' + this.search_term, { withCredentials: false }).then((response) => {
					this.fixtures = response.data;
				}).catch((error) => {
					console.log(error.response);
				})
			} else {
				this.fixtures = [];
			}
		},
	},
})