import { defineStore } from 'pinia';

export const useGeneralStore = defineStore('general', {
	state: () => ({
		general: null
	}),
	getters: {
		getGeneral(state) {
			return state.general;
		}
	},
	actions: {
		setGeneral(data) {
			this.general = data;
		}
	},
})