<!-- eslint-disable -->
<script setup>
import { onMounted, ref } from 'vue';
import { useOddsStore } from '@/stores/odds';
import { useCustomerStore } from '@/stores/customer';

const oddsStore = useOddsStore();
const customerStore = useCustomerStore();
const betslip_changes = ref('');


const toggleBetslipChangesPreferences = (betslip_changes_option) => {
	const payload = { betslip_changes: 'always_ask'};
	if (betslip_changes.value != betslip_changes_option) payload.betslip_changes = betslip_changes_option;

	accountDetailsStore.updateCustomerOdds(payload).then( () => {
		betslip_changes.value = payload.betslip_changes
	})
}

</script>

<template>
    <div class="content-main">
        <div class="title-main">
            <img src="@/assets/images/odd-ico.svg" alt="Lang Icon">
            <h3>{{ $t('general.odds_format') }}</h3>
        </div>
        <form>
            <div class="lang-options">
                <div  class="radio-btn" @click="oddsStore.changeOddsPreview('decimal')">
                <input :checked="'decimal' == oddsStore.getOddsPreview || oddsStore.getOddsPreview == null" type="radio" name="odds" value="decimal">
                <label>{{ $t('general.decimal') }}</label>
                </div>
                <div class="radio-btn" @click="oddsStore.changeOddsPreview('american')">
                    <input :checked="'american' == oddsStore.getOddsPreview" type="radio" name="odds" value="american">
                    <label>{{ $t('general.american') }}</label>
                </div>
                <div class="radio-btn" @click="oddsStore.changeOddsPreview('fractional')">
                    <input :checked="'fractional' == oddsStore.getOddsPreview" type="radio" name="odds" value="fractional">
                    <label>{{ $t('general.fractional') }}</label>
                </div>
            </div>
        </form>
    </div>
</template>