<!-- eslint-disable -->
<script setup>
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const score_title = ref(t('scoreboard.map'));

const props = defineProps({
	fixture_data: {
		type: Object,
		required: true
	}
})

const getScoreTitle = () =>{
	let sport_name = props.fixture_data.sport_name;
	score_title.value = (sport_name=='Dota 2'|| sport_name=='League of Legends') ? t('scoreboard.game'): t('scoreboard.map');
}

watch(() => props.fixture_data, () => {
	getScoreTitle();
});

onMounted(() => {
	getScoreTitle();
})
</script>
<template>
	<div class="scorecard-inner-main">
		<div class="scorecard-inner">
			<div class="team-name">
			</div>
			<div class="scorecard-score-outer">
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_1">
					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<h6 :class="{ 'active': fixture_data.statistics.period === 'M1' || fixture_data.statistics.period === 'G1' }">{{ score_title }} 1</h6>
						<template #content>
							<div class="tooltip">{{ score_title }} 1 </div>
						</template>
					</Popper>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_2">
					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<h6 :class="{ 'active': fixture_data.statistics.period === 'M2' || fixture_data.statistics.period === 'G2' }">{{ score_title }} 2</h6>
						<template #content>
							<div class="tooltip">{{ score_title }} 2 </div>
						</template>
					</Popper>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_3">
					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<h6 :class="{ 'active': fixture_data.statistics.period === 'M3' || fixture_data.statistics.period === 'G3' }">{{ score_title }} 3</h6>
						<template #content>
							<div class="tooltip">{{ score_title }} 3 </div>
						</template>
					</Popper>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_4">
					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<h6 :class="{ 'active': fixture_data.statistics.period === 'M4' || fixture_data.statistics.period === 'G4' }">{{ score_title }} 4</h6>
						<template #content>
							<div class="tooltip">{{ score_title }} 4 </div>
						</template>
					</Popper>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_5">
					<Popper arrow hover openDelay="100" closeDelay="100" placement="top">
						<h6 :class="{ 'active': fixture_data.statistics.period === 'M5' || fixture_data.statistics.period === 'G5' }">{{ score_title }} 5</h6>
						<template #content>
							<div class="tooltip">{{ score_title }} 5 </div>
						</template>
					</Popper>
					<div class="five tooltip">
						<h6 :class="{ 'active': fixture_data.statistics.period === 'M5' || fixture_data.statistics.period === 'G5' }">{{ score_title }} 5</h6>
						<span class="tooltiptext">{{ score_title }} 5</span>
					</div>
				</div>
			</div>
		</div>

		<div class="scorecard-inner">
			<div class="team-name">
				<h5>{{fixture_data.participants[0]}}</h5>
			</div>
			<div class="scorecard-score-outer">
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_1">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M1' || fixture_data.statistics.period === 'G1' }">{{ fixture_data.scoreboard?.map_1.p1 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_2">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M2' || fixture_data.statistics.period === 'G2' }">{{ fixture_data.scoreboard?.map_2.p1 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_3">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M3' || fixture_data.statistics.period === 'G3' }">{{ fixture_data.scoreboard?.map_3.p1 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_4">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M4' || fixture_data.statistics.period === 'G4' }">{{ fixture_data.scoreboard?.map_4.p1 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_5">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M5' || fixture_data.statistics.period === 'G5' }">{{ fixture_data.scoreboard?.map_5.p1 }}</h6>
				</div>
			</div>
		</div>

		<div class="scorecard-inner">
			<div class="team-name">
				<h5>{{fixture_data.participants[1]}}</h5>
			</div>
			<div class="scorecard-score-outer">
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_1">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M1' || fixture_data.statistics.period === 'G1' }">{{ fixture_data.scoreboard?.map_1.p2 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_2">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M2' || fixture_data.statistics.period === 'G2' }">{{ fixture_data.scoreboard?.map_2.p2 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_3">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M3' || fixture_data.statistics.period === 'G3' }">{{ fixture_data.scoreboard?.map_3.p2 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_4">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M4' || fixture_data.statistics.period === 'G4' }">{{ fixture_data.scoreboard?.map_4.p2 }}</h6>
				</div>
				<div class="scorecard-score-inner" v-if="fixture_data.scoreboard?.map_5">
					<h6 :class="{ 'active': fixture_data.statistics.period === 'M5' || fixture_data.statistics.period === 'G5' }">{{ fixture_data.scoreboard?.map_5.p2 }}</h6>
				</div>
			</div>
		</div>

	</div>
</template>
<style scoped>
.sports-main .eventview-pannel .sports-center-content .eventview-game-banner.eventview-set_game-banner .live-game-outer .live_score_Box .league-score .scorecard-outer .scorecard-inner-main .scorecard-inner .scorecard-score-outer .scorecard-score-inner:first-child h6 {
	color: unset;
}
.tooltip {
	width: 50px;
	background-color: #63A246;
	padding: 5px 0;
	text-align: center;
	border: 1px solid #63A246;
	border-radius: 50px !important;
	font-size: 10px;
	color: #ffffff;
}
:deep(.popper) {
	background-color: #63A246;
	padding: 20px;
	border-radius: 20px;
	font-weight: bold;
	margin-right: 30px;
}

:deep(.popper #arrow::before) {
	background: #63A246;
	left: 0px;
}
:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
	background: #63A246;
}
</style>
