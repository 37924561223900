import de from './de.json';
import en from './en.json';
import es from './es.json';
import fa from './fa.json';
import fr from './fr.json';
import gr from './gr.json';
import pt from './pt.json';
import sr from './sr.json';
import tr from './tr.json';

// Export an object with keys corresponding to the locales
export default {
  de,
  en,
  es,
  fa,
  fr,
  gr,
  pt,
  sr,
  tr,
};