<!-- eslint-disable -->
<script setup>
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { useCustomerStore } from '@/stores/customer';
import { onMounted, ref } from 'vue';
import { closeModal, confirmModal } from '@kolirt/vue-modal'

const svgs_data = commonSvgs;
const customerStore = useCustomerStore();

const props = defineProps({
	title: {},
	body: {},
	notification: {}
})

const closeModalOnClickOutside = (event) => {
	// Check if the click occurred outside the modal
	if (!event.target.closest('.modal-content')) {
		closeModal();
		// Call the modalClosedCallback passed from the parent
		customerStore.customer_notification_id = null;
	}

	if (props.notification.read_at == null) {
		let payload = {
			page: 1
		};
		customerStore.updateCustomerNotification(props.notification).then(() => {
			customerStore.fetchCustomerNotifications(payload).then(() =>{

			});
		});
	}
};

</script>
<template>

	<div class="modal-outer verify-email-popup" ref="modal" @click="closeModalOnClickOutside">
		<!-- overlay -->
		<div class="overlay"></div>
		<!-- modal -->
		<div class="modal">
			<button class="close" @click="closeModalOnClickOutside"  v-html="svgs_data.popup_cross"></button>

			<template v-if="props">
				<div class="modal-header">
					<h3>{{ props.title }}</h3>
				</div>
				<div class="password-reset-main">
					<div class="popup-message">
						<p v-html="props.body"></p>
					</div>
					<button class="btn btn-secondary" type="button" @click="closeModalOnClickOutside">{{ $t('general.close') }}</button>
				</div>
			</template>
		</div>

	</div>
</template>
<style scoped>
/* */
</style>