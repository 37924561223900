<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';

const { t } = useI18n();
const registerStore = useRegisterStore();
</script>

<template>
	<div class="register-form-field">
			<label>{{$t('account.register.postal_code')}}</label>
			<input class="form-input" :placeholder="$t('account.register.postal_code')" @keyup="registerStore.validatePostCode" v-model="registerStore.register_input_fields.post_code" type="text" name="postcode"
			required="required" />
			<div class="register-form-list" v-if="registerStore.register_errors.post_code.error">
				<div class="password-meter">
					<div class="password-meter-bar">
						<div class="password-meter-bar-inn"></div>
					</div>
				</div> 
				<p>
					<img src="@/assets/svgs/cross.svg" alt=""/>
					&nbsp;
					<span v-if="registerStore.register_errors.post_code.error === 'error_length_between'">
						{{ $t('account.register.error_length_between') }}
					</span>
					<span v-else-if="registerStore.register_errors.post_code.error === 'error_only_numbers'">
						{{ $t('account.register.error_only_numbers') }}
					</span>
					<span v-else-if="registerStore.register_errors.post_code.error === 'error_post_code_required'">
						{{ $t('account.register.error_post_code_required') }}
					</span>
					<span v-else-if="registerStore.register_errors.hasOwnProperty('post_code')">
						{{ registerStore.register_errors.post_code.error }}
					</span>
				</p>
			</div>
		</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input { 
	padding: 10px 15px 10px 15px;
}
</style>