<!-- eslint-disable -->
<script setup>
import { onMounted, ref } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import NotificationsModal from '@/components/modals/NotificationsModal.vue';
import { openModal } from '@kolirt/vue-modal';
import { useDatesStore } from '@/stores/dates';

const dateStore = useDatesStore();
const svgs_data = commonSvgs;
const customerStore = useCustomerStore();

const openPopup = (notification) => {
	let payload = {
		page: 1
	};

	customerStore.customer_notification_id = notification.id;

	if (notification.read_at == null) {
		customerStore.updateCustomerNotification(notification).then(() => {
			customerStore.fetchCustomerNotifications(payload).then(() =>{
				openModal(NotificationsModal, { title: notification.title, body: notification.body, notification: notification }).catch(() => {});
			});

		})
	} else {
		openModal(NotificationsModal, { title: notification.title, body: notification.body, notification: notification }).catch(() => {});
	}
};
</script>
<template>
	<div class="notifications-dropdown-inner">
		<template v-if="customerStore.customerNotifications">
			<div class="offers-accordion">
				<div class="offers-accordion-inner" >
					<div class="title">
						<h3>Notifications</h3>
					</div>
				</div>
				<div class="offers-empty" v-if="customerStore.customerNotifications.data.length == 0">
					<p>{{ $t('general.no_notifications') }}</p>
				</div>

				<div @click="openPopup(notification)" v-else class="offers-accordion-inner" v-for="(notification, index) in customerStore.customerNotifications.data" :key="notification.id" :class="notification.value == index+'index'? 'active':''" >
					<div class="offers-top-header">
						<h3>{{ notification.title }}</h3>
						<h3 class="side-message-box" :class="{'message-unread': notification.read_at === null}"></h3>
						<span class="date" > {{ dateStore.messageDate(notification.created_at) }}</span>
					</div>
					<div class="offers-tab-main" >
						<div class="offer-content-inner notification-inner" :class="customerStore.customer_notification_id == notification.id ? 'active' : ''">
							<p v-html="notification.body"></p>
						</div>
					</div>
					<div class="offers-tab-content">
					</div>
				</div>
				<div v-if="customerStore.customerNotifications.data" class="user-amount-btn notifications-btn">
					<router-link :to="{ name: 'account-notifications' }">{{ $t('general.go_to_notifications') }}</router-link>
				</div>
			</div>
		</template>

	</div>
</template>
<style scoped>
.offers-accordion .user-amount-btn a{
	color: aliceblue !important;
	display: flex !important;
	justify-content: center !important;
}

.offers-top-header {
	grid-template-columns: 1fr auto auto !important;
}
.notifications-btn {
	margin-top: 15px;
}

.offers-empty{
	margin-top: 10px;
}

.message-unread {
	background: #FFBB02;
	border-radius: 50%;
	width: 7px;
	height: 7px;
	display: flex;
	margin-top: 7px;
	position: relative;
	margin-right: 10px;
}

.date {
	font-size: 11px;
	color: #83878D;
	padding: 3px 0;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
}
.offers-tab-main {
	margin-top: 10px;
}

.notifications-main .notifications-dropdown-main .notifications-dropdown-inner .offers-accordion .offers-accordion-inner.active .offers-top-header span {
	transform: unset !important;
}

.notification-inner {
	color: white;
}
</style>