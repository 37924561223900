<script setup>
/* eslint-disable */
import { useRouter } from 'vue-router';
import { onBeforeMount } from 'vue';
import { useAffiliatesStore } from '@/stores/affiliates';
import Footer from "../components/Common/Footer.vue";
import Header from "../components/Home/headerHome.vue";

const router = useRouter();
const affiliates = useAffiliatesStore();

onBeforeMount(() => {
	// Create payload
	let payload = {};

	// Add query parameters to payload
	if (Object.hasOwnProperty.call(router.currentRoute.value.query, 'btag')) {
		payload['btag'] = router.currentRoute.value.query.btag;
	}

	if (Object.hasOwnProperty.call(router.currentRoute.value.query, 'link')) {
		payload['link'] = router.currentRoute.value.query.link;
	}

	if (Object.hasOwnProperty.call(router.currentRoute.value.query, 'banner')) {
		payload['banner'] = router.currentRoute.value.query.banner;
	}

	// If payload is empty then redirect to home page
	if (Object.keys(payload).length == 0) {
		router.push('/');
	}

	// Get landing page
	affiliates.getLandingPage(payload).then((response) => {
		if (response.redirect_url) {
			let url = null;

			// Check if URL method is supported
			if ('URL' in window) {
				url = new URL(response.redirect_url, window.location.origin);

				if (url.origin === window.location.origin) {
					// Use router.push with the pathname
					router.push(url.pathname);
				}
			}

			// If it's an external URL, fall back to location.href
			location.href = response.redirect_url;
		}

		// Redirect to home page
		router.push('/');
	}).catch((error) => {
		// Redirect to home page
		router.push('/');

		console.log('error', error);
	})
});
</script>

<template>
	<div class="main_div">
		<Header></Header>
		<div class="sports-main casino-detail promotion-detail">
			<div class="container-full">
				<div class="sports-main-outer">
					<div class="casino-detail-main promotion-detail-main">

						<div class="casino-detail-outer">
							<div class="detail">
								<div id="fullscreen-loader" class="fullscreen-loader">
									<div class="loader"></div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<style scoped>
.detail {
	height: 80vh;
	width: 100vw !important;
}

.fullscreen-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
}

.loader {
	border: 5px solid #f2f2f7;
	border-top: 5px solid #848484;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>



