<script setup>
/* eslint-disable */
import { openModal } from '@kolirt/vue-modal';
import { useRouter } from 'vue-router';
import { computed, watch, onMounted, ref } from 'vue';
import { BetSlip } from '@/stores/BetSlip';
import { useLanguagesStore } from '@/stores/languages';
import { useCurrenciesStore } from '@/stores/currencies';
import { useI18n } from 'vue-i18n';

const betslipStore = BetSlip();
const currenciesStore = useCurrenciesStore();
const isChecked = ref(false);
const isDropdownCheck = ref(false);
const isTooltip = ref(false);
const { t } = useI18n();
const defaultText = ref(t('betslip.select'));

let dT = betslipStore.betslip.bets[props.bet.odd_info.uuid].freebet_id ? true : false;

const dafaultToggle = ref(dT);
const selectedFreebet = ref(null);
const TooltipOpen = () => {
	isTooltip.value = !isTooltip.value;
}

const props = defineProps({
	bet: {
		required: true,
	},
	uuid:{
		required:true
	},
	frominput:{
		required:true
	}
});

watch(() => betslipStore.betslip.bets[props.bet.odd_info.uuid].freebet_id, (newValue, oldValue) => {
	if(!newValue){
		dafaultToggle.value = false;
		defaultText.value = t('betslip.select');
		isChecked.value = false;
	}
})

const toggleFreebet = (freebet, odd_uuid) => {
	selectedFreebet.value = freebet;

	const res = betslipStore.toggleFreebetOnSingle({ odd_uuid: odd_uuid, freebet: freebet });

	if (res != "exists") {
		selectItem(currenciesStore.format_currency(freebet.amount), odd_uuid);
	}
}

// Function to toggle the checkbox state
const toggleCheckbox = (keypress = false, freebet, uuid) => {
	const checkbox = document.getElementById('multi-toggle-input');
	if (keypress) {
		if (checkbox && checkbox.checked) {
			checkbox.checked = false;
			isChecked.value = false;
			betslipStore.toggleFreebetOnSingle({ odd_uuid: uuid, freebet: selectedFreebet.value, same_click : true});
			defaultText.value = 'Select';

		}
	} else {
		if(isChecked.value) {
			betslipStore.removeSingleFreebet(uuid);
			defaultText.value = 'Select';
		}
		isChecked.value = !isChecked.value;
	}
}

const dropdownOpen = () => {
	isDropdownCheck.value = !isDropdownCheck.value;
	setTimeout(() => {
		if (isDropdownCheck.value == true) {
			const parentElement = document.querySelector('.inner-tab-content');
			if (parentElement) {
				parentElement.scrollBy({
				top: 100,
				behavior: 'smooth' // Use smooth scrolling effect
				});
			}
		}
	}, 100);
}

const selectItem = (value) => {
	defaultText.value = value;
	isDropdownCheck.value = false; // Close dropdown
}

const closeDropdown = () => {
	isDropdownCheck.value = false;
}

</script>
<template>
	<div class="freebet-main" >
		<div class="freebet-inner">
			<div class="left">
				<p>{{ $t('betslip.freebet') }}</p>
			</div>
			<div class="right">
				<div class="toggle-btn">
					<label class="switch">
						<input type="checkbox" v-model="dafaultToggle" id="multi-toggle-input" @click="toggleCheckbox(false, freebet, uuid)">
						<span class="toggle-slider round"></span>
					</label>
				</div>
			</div>
		</div>

		<div class="freebet-inner freebet-amount-inner" :class="{ 'freebet-inner-show': isChecked || dafaultToggle }">
			<div class="left">
				<p>{{ $t('betslip.select_your_freebet') }}</p>
			</div>
			<div class="right">
				<div class="dropdown-input"  id="freebet_dropdown" v-click-outside-element="closeDropdown" :class="{ 'dropdown-open': isDropdownCheck }">
					<div class="default-value" @click="dropdownOpen">
						<p :class="{'selected' : defaultText !== 'Select'}">{{ defaultText}}</p>
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414" viewBox="0 0 11.414 6.414">
							<g id="Group_227" data-name="Group 227" transform="translate(-1662.793 -579.793)">
								<line id="Line_5" data-name="Line 5" x2="5.068" y2="5" transform="translate(1663.5 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
								<line id="Line_6" data-name="Line 6" x1="5.068" y2="5" transform="translate(1668.432 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
							</g>
							</svg>
						</span>
					</div>
					<div class="dropdown-inner-items">
						<div class="dropdown-item" v-for="freebet in betslipStore.multipleBetslipEligibleFreebets" :key="freebet.id" @click="selectItem(currenciesStore.format_currency(freebet.amount));toggleFreebet(freebet, uuid);">
							<p>{{currenciesStore.format_currency(freebet.amount)}}</p>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<style scoped>

.freebet-main {
  position: relative; /* Ensure relative positioning for absolute child elements */
}

.dropdown-input {
  position: relative; /* Ensure relative positioning for absolute child elements */
}

.dropdown-inner-items {
  display: none;
  position: absolute;
  top: 100%; /* Position dropdown below the input */
  left: 0;
  width: 100%; /* Full width of parent */
  background-color: #fff;
  z-index: 9999; /* Ensure dropdown appears on top of everything */
}

.dropdown-open .dropdown-inner-items {
  display: block;
}

.dropdown-inner-items {
	z-index: 999 !important;
	overflow: auto !important;
	max-height: 100px;
	height: auto;
}
</style>