<script setup>
 /* eslint-disable */
import { ref } from "vue";
import { useRacingSportsStore } from '@/stores/racing_sports';
import RacingRightSidebarFixture from '@/components/racing_sports/RacingRightSidebarFixture.vue'
const racingSportsStore = useRacingSportsStore();
const collapsed = ref(false);

</script>
<template>
	<div class="right-sidebar-racing" :class="{ 'collapsed': collapsed }">
		<div class="title">
			<h6>{{ $t("racing.upcoming_races") }} </h6>
			<span class="collapse-ico" @click="collapsed = !collapsed">
				<svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="5.414" viewBox="0 0 9.414 5.414">
				<g id="Group_232" data-name="Group 232" transform="translate(8.707 0.707) rotate(90)">
					<line id="Line_7" data-name="Line 7" x1="4" y2="4" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-width="1"/>
					<line id="Line_8" data-name="Line 8" x1="4" y1="4" transform="translate(0 4)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-width="1"/>
				</g>
				</svg>
			</span>
		</div>
		<div class="right-sidebar-inner">
			<!-- sidebar item main start -->
			<RacingRightSidebarFixture v-for="fixture in racingSportsStore.next3HourFixturesRightSidebar" :key="fixture.id" :fixture="fixture"></RacingRightSidebarFixture>
			<!-- sidebar item main end -->
		</div>
	</div>
</template>