<!-- eslint-disable -->
<script setup>
import { useRouter } from 'vue-router';
import { useAccountStore } from '@/stores/account';

const accountStore = useAccountStore()
const router = useRouter();

const goToRoute = (route_name) => {
	if (route_name) router.push({ name: route_name });
	else router.push({ name: 'landing' })
}

const goToPreviousPage = (route_path) => {
	if (route_path) router.push({ path: route_path });
	else router.push({ name: 'landing' })
}
</script>

<template>
	<div class="tab">
		<button @click="goToRoute('player-protection-time-out')" class="inner-tab-item"
			:class="router.currentRoute.value.name == 'player-protection-time-out' ? 'tablinks active' : 'tablinks'" style="cursor: pointer">{{ $t('account.customer_protection.time_out_title') }}</button>
		<button @click="goToRoute('player-protection-time-limit')" class="inner-tab-item"
			:class="router.currentRoute.value.name == 'player-protection-time-limit' ? 'tablinks active' : 'tablinks'" style="cursor: pointer">{{ $t('account.customer_protection.time_limits_title') }}</button>
	</div>
</template>
