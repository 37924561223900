<script setup>
// eslint-disable-next-line no-unused-vars
import { closeModal } from '@kolirt/vue-modal'
import { useRouter } from 'vue-router'
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const props = defineProps({
	errors: {
		type: Object,
		required: false
	},
	delete: {
		type: Boolean,
		required: false
	},
	need_redirect: {
		type: Boolean,
		required: false
	}
})

const router = useRouter()
const svgs_data = commonSvgs


const goToLanding = () => {
	if (props.need_redirect) {
		router.push({ name: 'landing' })
		closeModal()
	} else closeModal()
}

</script>
<template>
	<div class="modal-outer verify-email-popup" ref="modal">
		<!-- overlay -->
		<div class="overlay"></div>
		<!-- modal -->
		<div class="modal">
			<button class="close" @click="goToLanding()"  v-html="svgs_data.popup_cross"></button>

			<div class="password-reset-main">
				<template v-if="props.errors">
					<div class="popup-message">
						<template v-if="props.errors.data.errors">
							<p v-for="error in props.errors.data.errors" :key="error"> {{ error[0] }} </p>
						</template>
						<template v-else>
							<p> {{ props.errors.data.error }} </p>
						</template>
					</div>
				</template>
				<template v-if="props.delete">
					<div class="popup-message">
						<p> {{ $t('my_bets.bet_can_not_be_placed') }} </p>
					</div>
				</template>
				<button class="btn btn-secondary" type="button" @click="goToLanding()">{{ $t('general.close') }}</button>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.popup-message p {
		font-size: 15px;
	}
</style>