<!-- eslint-disable -->
<script setup>
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';

const { t } = useI18n();
const registerStore = useRegisterStore();
</script>

<template>
	<div class="register-form-field">
		<label>{{$t('account.register.name')}}</label>
		<input class="form-input" @keyup="registerStore.validateFirstname" :placeholder="$t('account.register.name')" v-model="registerStore.register_input_fields.firstname" type="text" name="full_name" required="required" />
		<div class="register-form-list" v-if="registerStore.register_errors.firstname.error">
			<div class="password-meter">
				<div class="password-meter-bar">
					<div class="password-meter-bar-inn"></div>
				</div>
			</div> 
			<p>
				<img src="@/assets/svgs/cross.svg" alt=""/>
				&nbsp;
				<span v-if="registerStore.register_errors.firstname.error === 'error_only_letter_characters'">
					{{ $t('account.register.error_only_letter_characters') }}
				</span>
				<span v-else-if="registerStore.register_errors.firstname.error === 'error_firstname_required'">
					{{ $t('account.register.error_firstname_required') }}
				</span>
				<span v-else-if="registerStore.register_errors.hasOwnProperty('firstname')">
					{{ registerStore.register_errors.firstname.error }}
				</span>
			</p>
		</div>
	</div>
</template>

<style scoped>
.register-form-main .register-outer-form .register-inner-form .password-meter .password-meter-bar {
	background-color:#FF6B73;
	margin-bottom:5px;
}
.register-form-main .register-form-list {
	margin-top:unset;
	padding-top:5px;
}
html[dir="rtl"] .register-form-main .register-outer-form .register-inner-form .form-input { 
	padding: 10px 15px 10px 15px;
}
</style>
