<!-- eslint-disable -->
<script setup>

import { onMounted, onUnmounted, ref } from 'vue'
import { closeModal } from '@kolirt/vue-modal'
import { useRouter } from 'vue-router';
import { useSportsbookSearchStore } from '@/stores/sportsbook_search';
import { useFixtureStore } from '@/stores/fixture';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useDatesStore } from '@/stores/dates';
import { useFavouritesStore } from '@/stores/favourites';

import commonSvgs from '@/assets/svgs/commonSvgs.json'
import headerIcons from '@/assets/svgs/Header.json'
import { useRacingSportsStore } from '@/stores/racing_sports';

const svg_data = ref(commonSvgs);
const header_icons = ref(headerIcons);

const router = useRouter();
const sportsbookSearchStore = useSportsbookSearchStore();
const fixtureStore = useFixtureStore();
const liveSportsStore = useLiveSportsStore();
const datesStore = useDatesStore();
const favouritesStore = useFavouritesStore();
const racingSportsStore = useRacingSportsStore();

onMounted(() => {
	setTimeout(() => {
		document.getElementById("searchField").focus();
	}, 200);

	document.addEventListener('click', clickOutsideModal);
});

const search = () => {
	sportsbookSearchStore.search();
}


const toggleFavourite = (fixture) => {
	favouritesStore.toggleFixture(fixture.id);
}

const goToFixture = (fixture) => {
	if (fixture.live) {
		router.push({ name: 'live-sports-eventview', params: { fixture_id: fixture.id } });
		closeModal();
		return;
	}

	if (racingSportsStore.racing_sport_ids.includes(fixture.sport_id)) router.push({ name: 'racing-fixture-book', params: { sport_id: fixture.sport_id, region_id: fixture.region_id, competition_id: fixture.competition_id, fixture_id: fixture.id } });
	else router.push({ name: 'pre-sports-fixturebook', params: { sport_id: fixture.sport_id, region_id: fixture.region_id, competition_id: fixture.competition_id, fixture_id: fixture.id } });
	closeModal();
}

const goToCompetition = (competition) => {
	router.push({ name: 'pre-sports-competition', params: { sport_id: competition.sport_id, region_id: competition.region_id, competition_id: competition.id } } )

	closeModal();
}

const clickOutsideModal = (event) => {

	const modal = document.getElementById('search-modal');

	if (modal && !modal.contains(event.target)) {
		closeModal();
	}
};

onUnmounted(() => {
	document.removeEventListener('click', clickOutsideModal);
})

</script>
<template>
<!-- eslint-disable -->
<section class="register-panel login-pannel" id="search-modal">
	<div class="container-full">
		<div class="modal">
			<!-- close icon -->
			<div class="modal-content">
				<div class="top-search-bar">
					<div></div>
					<div class="header-search-field">
						<div class="header-search-inner">
							<input id="searchField" type="search" :placeholder="$t('general.search_for_sports_competitions_or_teams')" v-model="sportsbookSearchStore.search_term" @keyup="search()">
							<button type="submit">
								<div>
									<object v-html="header_icons.search"></object>
								</div>
							</button>
						</div>
					</div>
					<button class="close" @click="closeModal"  v-html="svg_data.popup_cross"></button>
				</div>

				<div class="search-main-area" v-if="sportsbookSearchStore.search_term.length < 3">
					<div class="search-no-data">
						<p>{{$t('general.search_results_when_not_found')}}. </p>
					</div>
				</div>
				<!-- result not found -->
				<div class="search-main-area" v-if="sportsbookSearchStore.search_term.length > 3 && sportsbookSearchStore.fixtures.length == 0">
					<div class="search-no-data">
						<p>{{ $t('general.no_search_results_found') }}. </p>
					</div>
				</div>

				<div class="search-main-area" v-if="sportsbookSearchStore.fixtures.length > 0">
					<div class="search-fixtures">
						<div class="search-result">
							<h3>{{ $t('general.fixtures') }}:</h3>
							<p>{{ $t('general.results') }}: <span>{{ sportsbookSearchStore.fixtures.length }}</span></p>
						</div>
						<!-- FIXTURES HERE -->
						 <div class="live-bet-dashboard" v-for="(competition, index) in sportsbookSearchStore.overviewFixtures" :key="index">
							<div class="live-tab-info" id="tabs">
								<div id="live" class="tab-content highlight-live">
									<div class="live-info-outer-main">
										<div class="live-info-outer">
											<div class="live-info-inner">
												<div class="live-info-team">
													<!-- live match name start-->
													<div class="live-match">
														<div class="team-flag">
															<img :src="'/assets' + competition[0].region_icon_url" alt="">
														</div>
														<div class="live-match-name">
															<h3>{{ competition[0].sport_name }} - {{ competition[0].region_name }} - {{ competition[0].competition_name }}</h3>
														</div>
													</div>

													<!-- live match name end-->
												</div>

											</div>
											 <div class="live-info-inner live_sport" v-for="fixture in competition" :key="fixture.id">
												<!-- live team info start -->
												<div class="live-teams" v-if="fixture.live" :class="!liveSportsStore.hasFixtureClock(fixture.sport_id) && !fixture.statistics.game && !fixture.statistics.half && !fixture.statistics.period ? 'hidden-live-data-desktop':''">
													<div class="star-ico" @click="toggleFavourite(fixture)">
														<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
															<path :style="{fill: fixture.favourite  ? '#63A246' : ''}" id="Icon_ionic-ios-star" :class="`fixture-${fixture?.id}`" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d"></path>
														</svg>
													</div>
													<div class="live-data-desktop" v-if="liveSportsStore.hasFixtureClock(fixture.sport_id)">
														<h3>
															<dl>
																{{ fixtureStore.fixtureClockTime(fixture) }}
																<dt>{{ fixtureStore.fixtureState(fixture) }}</dt>
															</dl>

															<span></span>
														</h3>
													</div>
													<div class="live-data-desktop" v-else>
														<h3>{{ fixtureStore.fixtureState(fixture) }}</h3>
														<span></span>
													</div>
													<div class="team-name" @click="goToFixture(fixture)">
														<div class="live-data-mobile" v-if="liveSportsStore.hasFixtureClock(fixture.sport_id)">
															<h3>
																<dl>
																	{{ fixtureStore.fixtureClockTime(fixture) }}
																	<dt>{{ fixtureStore.fixtureState(fixture) }}</dt>
																</dl>

																<span></span>
															</h3>
														</div>
														<div class="live-data-mobile" v-else>
															{{ fixtureStore.fixtureState(fixture) }}
															<span></span>
														</div>
														<div class="team-inner">
															<div class="score-grid multiple-score">
																<span>{{ fixture.statistics?.p1_score }}</span>
																<span v-if="liveSportsStore.hasThreeScores(fixture.sport_id)">{{ fixture.statistics?.p1_games }}</span>
																<span v-if="liveSportsStore.hasThreeScores(fixture.sport_id) || liveSportsStore.hasTwoScores(fixture.sport_id)">{{ fixture.statistics?.p1_points }}</span>
															</div>

															<p>{{ fixture.participants[0] }}
																<span class="playing-team" v-if="fixture.statistics?.possession==1"></span>
															</p>
														</div>
														<div class="team-inner">
															<div class="score-grid multiple-score">
																<span>{{ fixture.statistics?.p2_score }}</span>
																<span v-if="liveSportsStore.hasThreeScores(fixture.sport_id)">{{ fixture.statistics?.p2_games }}</span>
																<span v-if="liveSportsStore.hasThreeScores(fixture.sport_id) || liveSportsStore.hasTwoScores(fixture.sport_id)">{{ fixture.statistics?.p2_points }}</span>
															</div>
															<p>{{ fixture.participants[1] }}
																<span class="playing-team" v-if="fixture.statistics?.possession==2"></span>
															</p>
														</div>
													</div>
													<div @click="goToFixture" class="betting-total-mobile">
														<p>
															<img src="/img/plus-m-bet.8497147a.svg" alt="">&nbsp;{{fixture.odds_count}} {{$t('account.affiliate.bets')}}
														</p>
													</div>
												</div>
												<div class="live-teams" v-else>
													<div class="star-ico" @click="toggleFavourite(fixture)">
														<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
															<path :style="{fill: fixture.favourite  ? '#63A246' : ''}" id="Icon_ionic-ios-star" :class="`fixture-${fixture?.id}`" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4e545d" />
														</svg>
													</div>
													<div class="upcoming-dates" @click="goToFixture(fixture)">
														<span>{{ datesStore.preFixtureBookDate(fixture?.start_datetime) }}</span>
														<span>{{ datesStore.preFixtureBookTime(fixture?.start_datetime) }}</span>
													</div>
													<div class="team-name" @click="goToFixture(fixture)">
														<div class="upcoming-dates-mobile">
															<span>{{ datesStore.preFixtureBookDate(fixture?.start_datetime) }}</span>
															<span>{{ datesStore.preFixtureBookTime(fixture?.start_datetime) }}</span>
														</div>
														<div class="team-inner">
															<p>{{fixture?.participants? fixture.participants[0]: ''}}
																<span class="playing-team" v-if="fixture.statistics?.possession==1"></span>
															</p>
														</div>
														<div class="team-inner">
															<p v-if="!liveSportsStore.hasOneParticipant(fixture.sport_id)">{{fixture?.participants? fixture.participants[1]: ''}}
																<span class="playing-team" v-if="fixture.statistics?.possession==2"></span>
															</p>
														</div>

													</div>
													<div @click="goToFixture" class="betting-total-mobile">
														<p><img src="@/assets/images/plus-m-bet.svg" alt="">&nbsp;{{ fixture?.odds_count }} {{$t('account.affiliate.bets')}}</p>
													</div>
												</div>
												<!-- live team info start -->
												<!-- match goals start -->
												<div class="match-score_info">
													<div class="total-bet">
														<div class="live-badge" v-if="fixture.live" v-html="svg_data.live_badge"></div>
														<div class="total-bet-main">
															<h6>{{ fixture.odds_count }} {{$t('account.affiliate.bets')}}</h6>
														</div>
													</div>
												</div>
												<!-- match goals end -->
											</div>

										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="search-competitions">
						<div class="search-result">
							<h3>{{ $t('general.competitions') }}</h3>
							<p>{{ $t('general.results') }}: <span>{{ sportsbookSearchStore.competitions.length }}</span></p>
						</div>

						<div class="competition-search-outer">
							<div class="competition-search-main" v-for="(competition, index) in sportsbookSearchStore.competitions" :key="index" @click="goToCompetition(competition)">
								<div class="game-icon">
									<img :src="liveSportsStore.liveSportWhiteIcon(competition.sport_id)" alt="">
								</div>
								<div class="country-info-inner top-league">
									<div class="country-left">
										<div class="country-flag">
											<img :src="'/assets' + competition.region_icon_url" alt="country-flag">
										</div>
										<div class="country-name text-capitalize">
											<h3>{{ competition.name }}</h3>
										</div>
									</div>
									<div class="country-right">
										<p>({{ competition.fixtures_count }})</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</section>

</template>
<style scoped>
.register-panel {
	width: 60% !important;
	height: 80% !important;
}

.top-search-bar {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 5% 90% 5%;
}

.close {
	cursor: pointer;
	margin-left: 5px;
}

@media screen and (min-width: 320px) and (max-width: 860px) {
	.register-panel {
		width: 100% !important;
		height: 100% !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1242px) {
	.register-panel {
		width: 100% !important;
		height: 100% !important;
	}
}

@media screen and (max-width: 991px) {
	.search-main-area {
		max-height: 660px;
	}
}

@media screen and (min-width: 1360px) and (max-width: 1599px) {
	.search-main-area {
		max-height: 660px;
	}
}

html[dir="rtl"] .top-search-bar .header-search-field button {
	right: unset;
	left: 14px;
}


</style>