import { defineStore } from 'pinia';
import {applyPatch} from 'rfc6902'
import axios from 'axios';
import _ from 'lodash';
import { useCustomerStore } from './customer';
import { useSocketStore } from './socket';
import { usePreSportsStore } from './pre_sports';

export const useFavouritesStore = defineStore('favourites', {
	state: () => ({
		fixture_ids: [],
		market_ids: [],
		betslip_ids: [],
		fixture_overviews: {},
	}),
	getters: {
		favouriteFixtureIds(state) {
			const customerStore = useCustomerStore();

			if (customerStore.isLoggedIn) {
				return _.map(customerStore.getCustomer.favourites.fixtures, 'fixture_id');
			}

			return state.fixture_ids;
		},
		favouriteMarketIds(state) {
			const customerStore = useCustomerStore();

			if (customerStore.isLoggedIn) {
				return _.map(customerStore.getCustomer.favourites.markets, 'market_id');
			}

			return state.market_ids
		},
		favouriteBetslipIds(state) {
			const customerStore = useCustomerStore();

			if (customerStore.isLoggedIn) {
				return _.map(customerStore.getCustomer.favourites.betslips, 'betslip_id');
			}

			return state.betslip_ids
		},
		liveSportOverviewFavourites(state) {
			let fixtures = _.filter(state.fixture_overviews, { live: true });

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {
					fixtures[fixture_id].favourite = true;
					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;
				}
			}

			let grouped_by_sport = _.chain(fixtures).groupBy('sport_id').value();

			for (const sport_id in grouped_by_sport) {
				if (Object.hasOwnProperty.call(grouped_by_sport, sport_id)) {
					const element = grouped_by_sport[sport_id];
					grouped_by_sport[sport_id] = _.groupBy(element, 'competition_region_name');
				}
			}

			return grouped_by_sport;
		},
		liveSportEventviewSidebarFavourites(state) {
			let fixtures = _.filter(state.fixture_overviews, { live: true });

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {
					fixtures[fixture_id].favourite = true;
					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;
				}
			}

			let grouped_by_sport = _.chain(fixtures).groupBy('sport_id').value();

			return grouped_by_sport;
		},
		preFixtureOverviews(state) {
			return _.filter(state.fixture_overviews, {'live': false});
		},
		preFixtureOverviewsBySport(state) {
			return _.chain(state.fixture_overviews).filter({live: false}).groupBy('sport_id').value();
		},
		fixtureOverviews(state) {
			return state.fixture_overviews;
		},
		firstFixtureOfSportOverview(state) {
			if (_.isEmpty(state.liveSportOverviewFavourites)) return;
			let sport_id = Object.keys(state.liveSportOverviewFavourites)[0];
			let competition_name = Object.keys(state.liveSportOverviewFavourites[sport_id])[0];

			return state.liveSportOverviewFavourites[sport_id][competition_name][0];
		},
		preLeftSidebarFavourites(state) {
			let fixtures = _.filter(state.fixture_overviews, { live: false });

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {
					fixtures[fixture_id].favourite = true;
					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;
				}
			}

			let grouped_competition_region = _.chain(fixtures).groupBy('competition_region_name').value();

			return grouped_competition_region;
		},
		esportsLeftSidebarFavourites(state) {
			const preSportsStore = usePreSportsStore();

			let all_fixtures = _.filter(state.fixture_overviews, { live: false });

			let esport_ids = preSportsStore.esports.map(esport => esport.id);

			let fixtures = all_fixtures.filter(fixture => esport_ids.includes(fixture.sport_id));

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {
					fixtures[fixture_id].favourite = true;
					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;
				}
			}

			let grouped_competition_region = _.chain(fixtures).groupBy('competition_region_name').value();

			return grouped_competition_region;
		},
		liveLeftSidebarFavourites(state) {
			let fixtures = _.filter(state.fixture_overviews, { live: true });

			for (const fixture_id in fixtures) {
				if (Object.hasOwnProperty.call(fixtures, fixture_id)) {
					fixtures[fixture_id].favourite = true;
					fixtures[fixture_id]['competition_region_name'] = fixtures[fixture_id].region_name + ' - ' + fixtures[fixture_id].competition_name;
				}
			}

			let grouped_competition_region = _.chain(fixtures).groupBy('competition_region_name').value();

			return grouped_competition_region;
		}
	},
	actions: {
		joinFavourites() {
			const socketStore = useSocketStore();
			for (let index = 0; index < this.favouriteFixtureIds.length; index++) {
				const fixture_id = this.favouriteFixtureIds[index];
				socketStore.getSocket.emit('join-favouriteFixture', fixture_id);
			}
		},
		toggleFixture(data) {
			const customerStore = useCustomerStore();
			const socketStore = useSocketStore();

			if (customerStore.isLoggedIn) {

				// Try to find fixture in customer favourites
				if (_.find(customerStore.getCustomer.favourites.fixtures, { fixture_id: data })) {
					axios.post(process.env.VUE_APP_API_URL + '/account/favourite/fixture/delete', { id: data }).then((response) => {
						customerStore.updateFavourites(response.data.data);
						socketStore.getSocket.emit('leave-favouriteFixture', data);
						this.deleteFavouriteFixture(data);
					})
				} else {
					axios.post(process.env.VUE_APP_API_URL + '/account/favourite/fixture', { id: data }).then((response) => {
						customerStore.updateFavourites(response.data.data);
						socketStore.getSocket.emit('join-favouriteFixture', data);
					})
				}

				return;
			}

			if (this.fixture_ids.includes(data)) {
				_.remove(this.fixture_ids, function(fixture_id) { return fixture_id == data });
				socketStore.getSocket.emit('leave-favouriteFixture', data);
				this.deleteFavouriteFixture(data);
				return;
			}

			this.fixture_ids.push(data);
			socketStore.getSocket.emit('join-favouriteFixture', data);
		},
		toggleMarket(data) {
			const customerStore = useCustomerStore();
			if (customerStore.isLoggedIn) {
				axios.post(process.env.VUE_APP_API_URL + '/account/favourite/market', { id: data }).then((response) => {
					customerStore.updateFavourites(response.data.data);
				})
				return;
			}

			if (this.market_ids.includes(data)) {
				_.remove(this.market_ids, function(market_id) { return market_id == data });
				return;
			}

			this.market_ids.push(data);
		},
		setFavouriteFixture(data) {
			this.fixture_overviews[data.id] = data;
		},
		updateFavouriteFixture(data) {
			applyPatch(this.fixture_overviews[data.id], data.patch);
		},
		deleteFavouriteFixture(data) {
			delete this.fixture_overviews[data];
		}
	},
	persist: {
		storage: localStorage,
		paths: ['fixture_ids', 'market_ids'],
	},
})