import { defineStore } from 'pinia';
import _ from "lodash";
import { useI18n } from 'vue-i18n';

export const usePaymentGatewaysStore = defineStore('payment_gateways', {
	state: () => ({
		payment_gateways: null,
		selected_payment_gateway: null,
		error_message: null
	}),
	getters: {
		getPaymentGatewayCurrencyValues: (state) => (currency) => {
			const { t } = useI18n();
			state.error_message = null;
			const selected_gateway = _.find(state.payment_gateways, { id: state.selected_payment_gateway});

			if (selected_gateway) {
				// Find the currency within the selected gateway's currencies array
				const selected_currency = _.find(selected_gateway.currencies, { currency_id: currency.id });

				// If the selected currency is found
				if (selected_currency && selected_gateway.supported_currencies.includes(currency.code)) {

					// Retrieve the min and max values
					return {
						min_deposit_amount: selected_currency.min_deposit_amount,
						max_deposit_amount: selected_currency.max_deposit_amount,
						min_withdraw_amount: selected_currency.min_withdraw_amount,
						max_withdraw_amount: selected_currency.max_withdraw_amount,
					};

				} else if(!selected_currency && selected_gateway.supported_currencies.includes(currency.code)) {
					return {
						min_deposit_amount: null,
						max_deposit_amount: null,
						min_withdraw_amount: null,
						max_withdraw_amount: null,
					};
				} else {
					state.error_message = t('account.register.currency') + ' ' + currency.name +  t('bank.is_not_supported');
					return null;
				}
			} else {
				state.error_message = t('bank.payment_not_supported');
				return null;
			}
		},
		getPaymentGateways(state) {
			return state.payment_gateways
		},
		getSelectedPaymentGateway(state) {
			return state.selected_payment_gateway
		}
	},
	actions: {
		setPaymentGateways(data) {
			this.payment_gateways = data;
		},
		setSelectedPaymentGateway(data) {
			this.selected_payment_gateway = data;
		}
	},
})