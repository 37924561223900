<script setup>
/* eslint-disable */
import { ref, computed ,onMounted} from 'vue';
import { BetSlip } from '@/stores/BetSlip';
import { useRouter } from 'vue-router';
import { useNavbarStore } from '@/stores/navbar_items';
import { useInitializeStore } from '@/stores/initialize';
import { useMenuStore } from '@/stores/menus';
import menuIcons from '@/assets/svgs/mobileNavigationSvgs.json'
import { useRacingSportsStore } from '@/stores/racing_sports';

const router = useRouter();
const navbarStore = useNavbarStore();
const racingSportsStore = useRacingSportsStore();
const betSlipStore = BetSlip();
const initializeStore = useInitializeStore();
const menusStore = useMenuStore();
const menu_icons = ref(menuIcons);
const showSportsMenu = ref(true);

const toggleModal = (type) => {
	betSlipStore.betSlipModel = betSlipStore.betSlipModel ? '' : type;

	if (betSlipStore.betSlipModel) navbarStore.closeAll();
}

const goToRoute = (menu) => {
	if (menu.click_url == '/pre-sports') {
		if (initializeStore.isMobile) {
			router.push({'name': 'pre-sports-coupons', params: {sport_id: ''}})
			return;
		}

		router.push({'name': 'pre-sports-highlights', params: {sport_id: ''}})
	} else if (menu.click_url == '/racing') {
		let first_sport = racingSportsStore.racingSports[0];

		router.push({name: 'racing-coupons', params: {sport_id: first_sport.id}});
		racingSportsStore.setSelectedRacingSportId(first_sport.id);

	} else {
		router.push({'path': menu.click_url})
	}
}
const toggleMenu = () => {
    showSportsMenu.value = !showSportsMenu.value;
};

const isRouteActive = (menu) => {
	if (menu.click_url.includes('/live-sports') && router.currentRoute.value.path.includes('/live-sports')) return true;
	if (menu.click_url !== '/' && router.currentRoute.value.path.includes(menu.click_url)) return true;
	if (menu.click_url === '/' && router.currentRoute.value.name == 'landing' ) return true

	return false;
};

const getSvg = (menu) => {
	if (menu.click_url == '/pre-sports') return 'Sports';
	else if (menu.click_url.includes('/live-sports')) return 'Live-Sports';
	else if (menu.click_url == '/live-casino') return 'Live-Casino';
	else if (menu.click_url == '/casino') return 'Casino';
	else if (menu.click_url == '/racing') return 'Racing';
	else  return null
};

const columnsGrid = computed(() => {
	let columns = '';

	// Generate the CSS for grid-template-columns property depending on menus length
	switch (menusStore.getMobileNavigationMenus.length) {
		case 2:
			columns = `repeat(${menusStore.getMobileNavigationMenus.length}, 1fr)`;
			break;
		case 3:
			columns = `repeat(${menusStore.getMobileNavigationMenus.length}, 1fr)`;
			break;
		case 4:
			columns = `repeat(${menusStore.getMobileNavigationMenus.length}, 1fr)`;;
			break;
		case 5:
			columns = `repeat(${menusStore.getMobileNavigationMenus.length}, 1fr)`;;
			break;
		case 6:
			columns = `repeat(${menusStore.getMobileNavigationMenus.length}, 1fr)`;;
			break;
		case 7:
			columns = `repeat(${menusStore.getMobileNavigationMenus.length}, 1fr)`;;
			break;
		default:
			columns =`1fr 1fr 1fr 1fr 1fr`;
			break;
	}

	return columns;
});

onMounted(() => {
	if(["/live-casino","/casino"].includes(router.currentRoute.value.path)){
		showSportsMenu.value = false
	}
});
</script>

<template>
	<template v-if="menusStore.getMobileNavigationMenus">
		
		<!-- <div class="fixed-btm-menu" :style="{ 'grid-template-columns': columnsGrid }">
			<template v-for="menu in menusStore.getMobileNavigationMenus">
				<a v-if="menu.click_url === '/betslip'" id="popup-link" class="betslip-menuItem" :class="betSlipStore.betSlipModel ? 'open':''" @click.prevent="toggleModal('betslip')">
					<div class="fixed-btm-inner" >
						<img src="@/assets/images/plus-ico.svg" alt="">
						<p> {{$t('general.betslip')}} <span v-if="betSlipStore.countBetslipBets">({{betSlipStore.countBetslipBets}})</span> </p>
					</div>
				</a>

				<a v-else style="cursor:pointer;" @click="goToRoute(menu)" :class="{'router-link-active router-link-exact-active': isRouteActive(menu) }">
					<div class="fixed-btm-inner" :class="isRouteActive(menu) ? 'active': ''">
						<template v-if="!menu.media_url">
							<p v-html="menu_icons[getSvg(menu)]"></p>
							<p >{{ menu.name }}</p>
						</template>
						<template v-else>
							<img :src="menu.media_url" class="image"/>
							<p>{{ menu.name }}</p>
						</template>
					</div>
				</a>
			</template>
		</div> -->

		<!-- New fixed menu start -->
		<div class="menuFixed-mobile-main">

			<!-- first menu start-->
			<div class="menuFixed-mobile-inner" v-show="showSportsMenu">
				<template v-for="menu in menusStore.getMobileNavigationMenus">
					<div v-if="['/pre-sports','/live-sports/overview'].includes(menu.click_url)" class="menuFixed-item" :class="isRouteActive(menu) ? 'active': ''"  @click="goToRoute(menu)">
						<div class="menu-icon">
							<img v-if="menu.click_url === '/pre-sports'" src="@/assets/images/sports.svg" alt="Sports Icon">
							<img v-else-if="menu.click_url === '/live-sports/overview'" src="@/assets/images/live-sports.svg" alt="Live Sports Icon">
						</div>
						<p>{{ menu.name }}</p>
					</div>
					<div v-else-if="menu.name === 'Betslip'" class="menuFixed-item" id="popup-link" :class="[{ 'open': betSlipStore.betSlipModel }]" @click="toggleModal('betslip')">
						<div class="menu-icon">
							<img src="@/assets/images/betslip.svg" alt="Betslip Icon">
						</div>
						<p>{{$t('general.betslip')}} <span v-if="betSlipStore.countBetslipBets">({{betSlipStore.countBetslipBets}})</span></p>
					</div>
					
				</template>
				<div class="menuFixed-item menuFixed-toggle" @click="toggleMenu">
					<img src="@/assets/images/casino-toggle.svg" alt="Casino Toggle Icon">
				</div>
			</div>
			<!-- first menu end-->

			<!-- second menu start-->
			<div class="menuFixed-mobile-inner" v-show="!showSportsMenu">
				<template v-for="menu in menusStore.getMobileNavigationMenus">
					<div v-if="['/live-casino','/casino'].includes(menu.click_url)" class="menuFixed-item" :class="isRouteActive(menu) ? 'active': ''"  @click="goToRoute(menu)">
						<div class="menu-icon">
							<img v-if="menu.click_url === '/live-casino'" src="@/assets/images/live-casino.svg" alt="Sports Icon">
							<img v-else-if="menu.click_url === '/casino'" src="@/assets/images/dice-n.svg" alt="Live Sports Icon">
						</div>
						<p>{{ menu.name }}</p>
					</div>
					
				</template>
				<div class="menuFixed-item" :class="activeMenu=='Aviator'?'active':''" @click="setActiveMenu('Aviator')">
					<div class="menu-icon">
						<img src="@/assets/images/aviator.svg" alt="Aviator Icon">
					</div>
					<p>Aviator</p>
				</div>
				<div class="menuFixed-item menuFixed-toggle" @click="toggleMenu">
					<img src="@/assets/images/sports-toggle.svg" alt="Sports Toggle Icon">
				</div>
			</div>
			<!-- second menu end-->
		</div>
		<!-- New fixed menu End -->
	</template>
</template>

<style scoped>
html[dir="rtl"] .fixed-btm-main .fixed-btm-menu a:nth-child(1) {
	border-left: 1px solid #4E545D;
	border-right: 1px solid #4E545D;
}

.image {
	width: 25px;
	height: 25px;
	opacity: 45%
}

.fixed-btm-menu .router-link-active .image {
	opacity: unset !important;
}

/* .router-link-active .fixed-btm-inner .image {
	background: linear-gradient(359deg, #63A246, #131C27);
  /* opacity: 0.1; Adjust opacity for a more subtle effect (optional) */
/* } */

/* html[dir="rtl"] deep:(.container-full ) {
	padding-left: unset;
	padding-right: unset
} */
</style>
