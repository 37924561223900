<script setup>
/* eslint-disable */
import { ref, watch } from "vue"
import { useOddsStore } from '@/stores/odds';
import { BetSlip } from '@/stores/BetSlip';

import menuIcons from '@/assets/svgs/NotificationMenu.json'
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svg_data = ref(commonSvgs);
const menu_icons = ref(menuIcons);

const oddsStore = useOddsStore();
const betslipStore = BetSlip();

const price_up = ref(false);
const price_down = ref(false);

const props = defineProps({
	overview_market_header_name: {
		type: String,
		required: true,
	},
	overview_odd: {
		// type: Object,
		required: true,
	},
	needs_indicator: {
		type: Boolean,
		required: true,
	},
	fixture_id: {
		required: true,
	}
})

const addToBetslip = () => {
	if (!props.overview_odd || props.overview_odd.status == 'suspended') return;

	betslipStore.toggleToBetslip({ odd_uuid: props.overview_odd.uuid, fixture_id: props.fixture_id })
}

watch(() => props.overview_odd, (newOdd, oldOdd) => {
	if (newOdd == null || oldOdd == null) return;

	if (newOdd.status == 'suspended' || oldOdd.status == 'suspended') return;

	// If changed overview market from overview
	if (newOdd.uuid != oldOdd.uuid) return;

	if (newOdd.price > oldOdd.price) price_up.value = true;
	if (newOdd.price < oldOdd.price) price_down.value = true;

	setTimeout(() => {
		price_up.value = false;
		price_down.value = false;
	}, 5000);
})

</script>

<template>
	<div @click="addToBetslip" class="betting-info-inner" :class="{'bet-up': price_up, ' bet-down': price_down, 'odd-selected': overview_odd && oddsStore.isOddInBetslip(overview_odd.uuid) }">
		<div class="betting-info-left">
			<h6 v-if="needs_indicator && overview_odd"> {{ props.overview_market_header_name  }} {{ '(' + overview_odd.name_overview   + ') ' }} </h6>
			<h6 v-else> {{ props.overview_market_header_name  }}</h6>
		</div>
		<div class="betting-info-right">
			<template v-if="overview_odd && !oddsStore.isOddSuspended(overview_odd)">
				<h6> {{ oddsStore.oddsFormat(overview_odd.price) }}</h6>
			</template>
			<template v-else>
				<h6 v-html="menu_icons.lock"></h6>
			</template>
		</div>
	</div>
</template>

