import { defineStore } from 'pinia';
import _ from 'lodash';
import { useCustomerStore } from './customer';

export const useLanguagesStore = defineStore('languages', {
	state: () => ({
		languages: null,
		selected_language: {}
	}),
	getters: {
		getLanguages(state){
			return state.languages
		},
		selectedLanguage(state) {
			const customerStore = useCustomerStore();
			if (customerStore.isLoggedIn) return customerStore.getCustomer.language;

			if (Object.keys(state.selected_language).length > 0) return state.selected_language;

			return _.find(state.languages, { default: true });
		}
	},
	actions: {
		setLanguages(data) {
			this.languages = data;
		},
		changeLanguage(payload) {
			const customerStore = useCustomerStore();

			if (customerStore.isLoggedIn) {
				return global.axios.post(process.env.VUE_APP_API_URL + '/language/change', payload).then(() => {
					location.reload();
				}).catch((error) => {
					return Promise.reject(error.response);
				})
			} else {
				const new_language = _.find(this.languages, { id: payload.language_id })
				this.selected_language = new_language
				location.reload()
			}
		}
	},
	persist: {
		storage: localStorage,
		paths: ['selected_language'],
	},
})