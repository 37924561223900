<script>
import { onMounted, ref } from 'vue';
import { useLanguagesStore } from '@/stores/languages';
import { useCustomerStore } from '@/stores/customer';

export default {
  setup() {
    const languagesStore = useLanguagesStore();
    const customerStore = useCustomerStore();
    const language_name = ref(null);
    const language = ref({ language_id: null });
    const betslip_changes = ref('');

    const changeLanguage = (id) => {
      language.value.language_id = id;
      languagesStore.changeLanguage(language.value);
    }

    onMounted(() => {
      language.value.language_id = languagesStore.selectedLanguage.id;
      language_name.value = languagesStore.selectedLanguage.name;
      if (customerStore.isLoggedIn) {
        betslip_changes.value = customerStore.getCustomer.customer_settings.betslip_changes;
      } else {
        betslip_changes.value = 'always_ask';
      }
    });

    return {
      languagesStore,
      language_name,
      language,
      changeLanguage
    };
  }
};
</script>


<template>
    <div class="content-main">
        <div class="title-main">
            <img src="@/assets/images/lang-ico.svg" alt="Lang Icon">
            <h3>{{ $t('account.account_preferences.language') }}</h3>
        </div>
        <form>
            <div class="lang-options">
                <div  class="radio-btn" v-for="(language,index) in languagesStore.getLanguages" :key="index">
                    <input type="radio" name="lang"  :value="language.id" :checked="language.id == languagesStore.selectedLanguage.id" @change="changeLanguage(language.id)">
                <label>{{language.name}}</label>
                </div>
            </div>
        </form>
    </div>
</template>