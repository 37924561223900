import { defineStore } from 'pinia';
import _ from 'lodash';
import { useCustomerStore } from './customer';

export const useCasinoStore = defineStore('casinoStore', {
	state: () => ({
		games: {},
		brands: {},
		last_played_games: {},
		search_term: null,
		show_favourites: false,
		search_is_brand_name: false,
		featured_slot_games: {},
		games_loading: false,
		show_more_providers: [],
	}),
	getters: {
		getGames(state) {
			if (!state.games['games']) return {};

			let games = _.chain(state.games['games']).cloneDeep().filter(game => game.image_url !== null).value();

			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = null;
				if (process.env.VUE_APP_LICENSE_ENABLED == 'true') favourite_record = _.find(favourites, {'game_id': String(game.slot_id)});
				else favourite_record = _.find(favourites, {'game_id': String(game.game_id)});

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, {'favourite': true});
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, {'favourite': false});

			games = [...favourite_games, ...not_favourite_games];

			let search_term = state.search_term ? state.search_term.toLowerCase() : null;
			if (search_term) {

				games = _.chain(games).filter(function (game) {
					if (state.search_is_brand_name) return game.label.toLowerCase().includes(search_term);
					return game.brand_game_name.toLowerCase().includes(search_term);
				}).value();

				if (!state.search_is_brand_name) {
					return state.sortGamesByBrand( _.mapValues(_.groupBy(games, 'label'), group => _.slice(group, 0, 13)));
				}

				return state.sortGamesByBrand(_.groupBy(games, 'label'));
			}

			// let result = _.mapValues(_.groupBy(games, 'label'), group => _.slice(group, 0, 13));
			let result = _.mapValues(_.groupBy(games, 'label'), (group, label) => {
				return this.show_more_providers.includes(label) ? group : _.slice(group, 0, 13);
			});

			return state.sortGamesByBrand(result)
		},
		sortGamesByBrand:() => (result) => {
			const sorted_keys = Object.keys(result).sort();
			const sorted_object = {};

			for (const key of sorted_keys) {
				sorted_object[key] = result[key];
			}

			return sorted_object;
		},
		getFavouriteGames(state) {
			if (!state.games['games']) return {};

			let games = _.cloneDeep(state.games['games']);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = null;
				if (process.env.VUE_APP_LICENSE_ENABLED == 'true') favourite_record = _.find(favourites, {'game_id': String(game.slot_id)});
				else favourite_record = _.find(favourites, {'game_id': String(game.game_id)});

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, {'favourite': true});

			return _.orderBy(favourite_games, ['favourite_timestamp', 'name'], ['desc', 'desc']);
		},
		brandNames(state) {
			if (!state.games['games']) return [];

			let games = _.chain(state.games['games']).sortBy('label').map('label').uniq().value();

			if (state.getFavouriteGames && state.getFavouriteGames.length > 0) {
				return ['All', 'Favourites', ...games];
			}

			return ['All', ...games];
		},
		brandFilter(state) {
			return state.search_term;
		},
		featuredSlotGames(state) {
			return _.filter(state.featured_slot_games, game => game.image_url !== null);
		},
		featuredSlotGamesFooter(state) {
			return _.chain(state.featured_slot_games).cloneDeep().filter(game => game.image_url !== null).take(8).value();
		},
		brandLogo() {
			return {
				'Ainsworth': '/assets/icons/casino/brands/ainsworth.svg',
				'Amatic': '/assets/icons/casino/brands/amatic.svg',
				'Apex': '/assets/icons/casino/brands/apex.svg',
				'Apollo': '/assets/icons/casino/brands/apollo.svg',
				'Aristocrat': '/assets/icons/casino/brands/aristocrat.svg',
				'EGT': '/assets/icons/casino/brands/egt.svg',
				'Fast Games': '',
				'Fish': '',
				'GClub': '/assets/icons/casino/brands/gclub.svg',
				'Habanero': '/assets/icons/casino/brands/habanero.svg',
				'Igrosoft': '/assets/icons/casino/brands/igrosoft.svg',
				'IGT': '/assets/icons/casino/brands/igt.svg',
				'Kajot': '/assets/icons/casino/brands/kajot.svg',
				'Live dealers': '',
				'Merkur': '/assets/icons/casino/brands/merkur.svg',
				'Microgaming': '/assets/icons/casino/brands/microgaming.svg',
				'More expensive': '',
				'Netent': '/assets/icons/casino/brands/netent.svg',
				'Novomatic': '/assets/icons/casino/brands/novomatic.svg',
				'Platipus': '/assets/icons/casino/brands/platipus.svg',
				'Pragmatic': '/assets/icons/casino/brands/pragmatic.svg',
				'QuickSpin': '/assets/icons/casino/brands/quickspin.svg',
				'Scientific Games': '/assets/icons/casino/brands/scientific.svg',
				'Sport Betting': '',
				'Table Games': '',
				'Tomhorn': '/assets/icons/casino/brands/tomhorn.svg',
				'Wazdan': '/assets/icons/casino/brands/wazdan.svg',
			}
		},
		showFavouritesGames(state) {
			return state.show_favourites;
		},
		getLastPlayedGames(state) {
			if (!state.games['games']) return {};

			let last_played_game_ids = _.map(state.last_played_games, 'game_id');

			// Filter the games based on the extracted game_ids
			let last_played_games = _.filter(state.games['games'], game => {
				let game_id = null;
				if (process.env.VUE_APP_LICENSE_ENABLED == 'true') game_id = String(game.slot_id);
				else game_id = String(game.game_id);

				return _.includes(last_played_game_ids, game_id) && game.image_url !== null;
			});

			// Add favourite flag to the games
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			last_played_games = _.map(last_played_games, game => {
				let favourite_record = null;
				if (process.env.VUE_APP_LICENSE_ENABLED == 'true') favourite_record = _.find(favourites, {'game_id': String(game.slot_id)});
				else favourite_record = _.find(favourites, {'game_id': String(game.game_id)});

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			return _.orderBy(last_played_games, ['favourite_timestamp', 'name'], ['asc', 'asc']);
		},
		getShowMoreProvider(state) {
			return state.show_more_providers;
		}
	},
	actions: {
		fetchGames(provider) {
			if (provider == this.selected_provider) provider = 'all';
			this.games_loading = true;

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/index', { provider: provider }, { headers: { 'Version': 'v2' } }).then((response) => {
				this.games_loading = false;
				this.games = response.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				this.games_loading = false;
				console.log(error.response);

				return Promise.reject(error);
			})
		},
		fetchLastPlayedGames() {
			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/last-played').then((response) => {
				this.last_played_games = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error);

				return Promise.reject(error);
			})
		},
		filterGames(search_term, is_brand_name) {
			this.search_term = search_term;
			this.search_is_brand_name = is_brand_name
			this.show_favourites = false;
		},
		showFavourites() {
			this.search_term = null;
			this.search_is_brand_name = false;
			this.show_favourites = true;
		},
		openGame(game_id) {
			let protocol = window.location.protocol;
			let hostname = window.location.hostname;
			let port = window.location.port ? window.location.port : '';
			let close_url = protocol + '//' + hostname + (port ? ':' + port : '') + '/casino/close';

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/opengame', { game_id: game_id, close_url: close_url }).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchFeaturedSlotGames(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/featured', payload).then((response) => {
				this.featured_slot_games = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		toggleFavourite(game_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/favourite/slot', { id: game_id }).then((response) => {
				useCustomerStore().customer.favourites = response.data.data

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		showMoreToggle(provider) {
			const index = this.show_more_providers.indexOf(provider);

			if (index !== -1) {
				// If the provider is already in the array, remove it
				this.show_more_providers.splice(index, 1);
			} else {
				// If the provider is not in the array, add it
				this.show_more_providers.push(provider);
			}
		},
	},
})