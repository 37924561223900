<script setup>
 /* eslint-disable */
import { onMounted, onBeforeMount, ref, onUnmounted } from 'vue';
import { openModal } from '@kolirt/vue-modal';

import { useSocketStore } from '@/stores/socket';
import { useCustomerStore } from '@/stores/customer';
import { MyBets } from '@/stores/MyBets';
import { BetSlip } from '@/stores/BetSlip';
import commonSvgs from '@/assets/svgs/commonSvgs.json';

import Single from "./BetSlip/SingleBet.vue"
import MultipleComponent from "./BetSlip/MultipleBets.vue"
import SystemBets from "./BetSlip/SystemBet.vue"
import ReceiptBetslip from "./BetSlip/ReceiptBetslip.vue"

import Betslip from './BetSlip/BetslipComponent.vue';
import MyBetsComponent from './MyBets/MyBets.vue';

import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';

const myBetsStore = MyBets();
const betslipStore = BetSlip();

const socketStore = useSocketStore();
const customerStore = useCustomerStore();

socketStore.getSocket.on('myBetsSnapshot', (data) => {
	myBetsStore.setMyBetsSnapshot(data);
});

// Fixture Odd Info Socket chanels
socketStore.getSocket.on('fixtureOddInfo', (data) => {
	myBetsStore.setFixtureOddInfo(data);
});

socketStore.getSocket.on('fixtureOddInfoInsert', (data) => {
	myBetsStore.setFixtureOddInfoInsert(data);
});

socketStore.getSocket.on('fixtureOddInfoUpdate', (data) => {
	myBetsStore.setFixtureOddInfoUpdate(data);
});

socketStore.getSocket.on('fixtureOddInfoDelete', (data) => {
	myBetsStore.setFixtureOddInfoDelete(data);
});

// Fixture Info Socket chanels
socketStore.getSocket.on('fixtureInfo', (data) => {
	myBetsStore.setFixtureInfo(data);
});

socketStore.getSocket.on('fixtureInfoUpdate', (data) => {
	myBetsStore.setFixtureInfoUpdate(data);
});

socketStore.getSocket.on('fixtureInfoDelete', (data) => {
	myBetsStore.setFixtureInfoDelete(data);
});

onBeforeMount(() => {
	myBetsStore.fetchMyBets();
});

onMounted(() => {
	toggleClass();
	setInterval(toggleClass, 500);

	setInterval(() => {
		myBetsStore.intervalFetchMybets();
	}, 20000);

	document.addEventListener('click', closeBetslipOnClickOutside);
});

const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() =>{});
}

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

const toggleModal = (type) => {
	if (type == 'mybet' && !customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	betslipStore.betSlipModel = type;
}

const removeAllBets = () => {
	betslipStore.resetBetslip();
	betslipStore.toggleBetslip();
}

const closeBetslipOnClickOutside = (event) => {
	const share_button = event.target.closest('.share-btn');
	const betslip_button = event.target.closest('.betslip-button');
	const odd_selected = event.target.closest('.betting_info');
	const match_score_inner = event.target.closest('.match-score-inner');
	const select_option = event.target.closest('.select-option');
	const odd_option = event.target.closest('.odds-sec');
	const upcoming_odd_option = event.target.closest('.upcoming-odd-main');
	const sharePopUp = event?.target?.classList.contains("ShareBtns")

	if (!event.target.closest('.form-popup') && !sharePopUp && !share_button && !betslip_button && !odd_selected && !match_score_inner && !select_option && !odd_option && !upcoming_odd_option) {
		if (betslipStore.is_betslip_opened == true) {
			betslipStore.is_betslip_opened = false;
			betslipStore.betSlipModel = '';
		}
	}
}

// adding class on body when betslipo is open in mobile
const toggleClass = () => {
	if (betslipStore.betSlipModel) {
		document.body.classList.add('betslip-show');
		betslipStore.is_betslip_opened = true
	} else {
		document.body.classList.remove('betslip-show');
		betslipStore.is_betslip_opened = false

	}
}

const closeForm = (type) => {
	betslipStore.betSlipModel = betslipStore.betSlipModel ? '' : type;
}

onUnmounted(() => {
	document.removeEventListener('click', closeBetslipOnClickOutside);
});
</script>

<template>
	<!-- eslint-disable -->
	<div class="eventime_mobal_content form-popup" id="myForm" :class="betslipStore.betSlipModel?'show':''">
		<div class="close_icon" :class="betslipStore.betSlipModel?'open':''">
			<button type="button" class="beat_btn  cancel" @click="closeForm('betslip')">
				<svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="9.414" viewBox="0 0 9.414 9.414">
					<g id="close" transform="translate(0.707 0.707)">
					<line id="Line_2" data-name="Line 2" x2="8" y2="8" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1"/>
					<line id="Line_3" data-name="Line 3" x1="8" y2="8" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-width="1"/>
					</g>
				</svg>
			</button>
		</div>
		<!-- nav -->
		<div class="cust_row">
			<div class="left" @click.prevent="toggleModal('betslip')">
				<a href="#" class="tab-button betslip-btn" :class="betslipStore.betSlipModel != 'mybet' ? 'active' :''"   data-tab="tab1">
					<div  @click="removeAllBets()" href="#" class="remove_btn">
						<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.741" viewBox="0 0 12 14.741">
							<g id="Group_1822" data-name="Group 1822" transform="translate(0.5 0.5)">
								<g id="delete" transform="translate(0)">
								<path id="Path_896" data-name="Path 896" d="M176,21.08A1.08,1.08,0,0,1,177.08,20h2.161a1.08,1.08,0,0,1,1.08,1.08" transform="translate(-172.507 -20)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
								<line id="Line_157" data-name="Line 157" x2="11" transform="translate(0 3.143)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
								<g id="Path_897" data-name="Path 897" transform="translate(-94.69 -135.265)" fill="none" stroke-linecap="round" stroke-miterlimit="10">
									<path d="M96,140l1.079,8.56a1.074,1.074,0,0,0,1.063.946h4.283a1.074,1.074,0,0,0,1.063-.946l1.079-8.56Z" stroke="none"/>
									<path d="M 97.13394165039062 141.0000152587891 L 98.07138824462891 148.4382934570312 C 98.07550048828125 148.4717102050781 98.10279846191406 148.5062866210938 98.14138793945312 148.5062866210938 L 102.4241638183594 148.5062866210938 C 102.4627304077148 148.5062866210938 102.4900283813477 148.4717102050781 102.4945220947266 148.4352569580078 L 103.4315795898438 141.0000152587891 L 97.13394165039062 141.0000152587891 M 96 140.0000152587891 L 104.5655212402344 140.0000152587891 L 103.4866714477539 148.560302734375 C 103.4211196899414 149.0935516357422 102.9704132080078 149.5062866210938 102.4241638183594 149.5062866210938 L 98.14138793945312 149.5062866210938 C 97.59510803222656 149.5062866210938 97.1444091796875 149.0935516357422 97.078857421875 148.560302734375 L 96 140.0000152587891 Z" stroke="none" fill="#dbe1e6"/>
								</g>
								<g id="Group_1823" data-name="Group 1823" transform="translate(4.804 7.854)">
									<line id="Line_158" data-name="Line 158" y1="3.165" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
									<line id="Line_159" data-name="Line 159" y1="3.165" transform="translate(1.571)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
								</g>
								</g>
							</g>
						</svg>
					</div>
					<span class="seprater"></span>
					<div class="tab-title">
						<h3>{{$t('general.betslip')}}</h3>
						<div class="tab-icon">
							<object v-html="commonSvgs.Betslip_ico"></object>
							<span v-if="betslipStore.countBetslipBets > 0">{{betslipStore.countBetslipBets}}</span>
						</div>
					</div>
					
				</a>
			</div>
			<div class="right" @click.prevent="toggleModal('mybet')">
				<a href="#" class="tab-button" data-tab="tab2" :class="betslipStore.betSlipModel == 'mybet' ? 'active' :''" >
					<div class="tab-title">
						<h3>{{$t('general.my_bets')}}</h3>
						<div class="tab-icon">
								<object v-html="commonSvgs.Betslip_ico"></object>
								<span v-if="customerStore.isLoggedIn">{{  myBetsStore?.unsettledBetslips.length }}</span>
						</div>
					</div>
				</a>
			</div>
		</div>
		<!-- end -->
		<template v-if="betslipStore.betSlipModel == 'betslip'">
			<Betslip/>
		</template>

		<template v-if="betslipStore.betSlipModel == 'mybet'">
			<MyBetsComponent/>
		</template>
	</div>
</template>

<style scoped>
.main_div {
	background-color: #202833;
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	html[dir="rtl"] .eventime_mobal_content .close_icon {
		position: absolute;
		top: -4px;
		right: unset;
		z-index: 10;
		border-radius: 50%;
		background: #131C27;
		width: 20px;
		height: 20px;
		padding: 0;
		cursor: pointer;
		left: -4px;
	}

	html[dir="rtl"] .eventime_mobal_content {
		position: fixed;
		bottom: 0;
		right: unset;
		width: 400px;
		border-radius: 5px 5px 0 0;
		display: block;
		z-index: 250;
		left: 59px;
	}
}


</style>