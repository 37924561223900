<script setup>
import { ref } from "vue";
import MyBet from './MyBetsIndex.vue';

import { MyBets } from '@/stores/MyBets';
import { BetSlip } from '@/stores/BetSlip';

const myBetActiveInnerTab = ref('unsettled');
const myBetsStore = MyBets();
const betslipStore = BetSlip();

</script>
<template>
 <!-- eslint-disable -->
<div class="my_bet_wrap tab-content active" :class="betslipStore.betSlipModel == 'mybet' ? 'active open' :''"   id="tab2">
	<div class="filter my_bet_filter">
		<ul>
			<li class="tab-item" @click="myBetActiveInnerTab = 'unsettled'" :class="myBetActiveInnerTab == 'unsettled' ? 'active' :''"><a href="#!"  >{{$t('my_bets.unsettled')}} ({{ myBetsStore?.unsettledBetslips.length }})</a></li>
			<li class="tab-item" @click="myBetActiveInnerTab = 'cash_out'" :class="myBetActiveInnerTab == 'cash_out' ? 'active' :''"><a href="#!">{{$t('my_bets.cash_out')}} ({{ myBetsStore?.cashoutBetslips.length }})</a></li>
			<li class="tab-item" @click="myBetActiveInnerTab = 'settles'" :class="myBetActiveInnerTab == 'settles' ? 'active' :''"><a href="#!">{{$t('my_bets.settled')}} ({{ myBetsStore?.settledBetslips.length }})</a></li>
		</ul>
	</div>

	<div class="my_bet-inner_content unsettled-bet"  :class="myBetActiveInnerTab == 'unsettled' ? 'active' :''">
		<div class="my_bet_game">
			<div class="bets_body" style="min-height: 100px;" v-if=" myBetsStore.unsettledBetslips?.length==0">
				<div class="bet-empty">
					<p>{{$t('my_bets.no_unsettled_bet')}}</p>
				</div>
			</div>

			<MyBet type="unsettled" :myData="myBetsStore.unsettledBetslips" v-else></MyBet>

			<div class="history_cta">
				<router-link to="/account/history/unsettled-bets" class="history btn-betslip">{{$t('general.see_all_history')}}</router-link>
			</div>
		</div>
	</div>

	<div class="my_bet-inner_content cashout-bet"  :class="myBetActiveInnerTab == 'cash_out' ? 'active' :''">
		<div class="my_bet_game">
			<div class="bets_body" style="min-height: 100px;" v-if=" myBetsStore.cashoutBetslips?.length==0">
				<div class="bet-empty">
					<p>{{$t('my_bets.no_cashed_out_bet')}}</p>
				</div>
			</div>

			<MyBet type="cashout" :myData="myBetsStore.cashoutBetslips" v-else></MyBet>
			<div class="history_cta">
				<router-link to="/account/history/settled-bets" class="history btn-betslip">{{$t('general.see_all_history')}}</router-link>
			</div>
		</div>
	</div>

	<div class="my_bet-inner_content cashout-bet" :class="myBetActiveInnerTab == 'settles' ? 'active' :''">
		<div class="my_bet_game">
			<div class="bets_body" style="min-height: 100px;" v-if=" myBetsStore.settledBetslips?.length==0">
				<div class="bet-empty">
				<p>{{$t('my_bets.no_settled_bet')}}</p>
				</div>
			</div>
			<MyBet type="settles" :myData="myBetsStore.settledBetslips" v-else></MyBet>

			<div class="history_cta">
				<router-link to="/account/history/settled-bets" class="history btn-betslip">{{$t('general.see_all_history')}}</router-link>
			</div>
		</div>
	</div>
</div>
</template>