<!-- eslint-disable -->
<script setup>
import { ref, onMounted} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRegisterStore } from '../../stores/register';
import _ from 'lodash';

const { t } = useI18n();
const registerStore = useRegisterStore();
const slugs_array = ref([]);

onMounted(() => {
	checkEnabledFields();
})

const checkEnabledFields = () => {
	const enabled_fields = registerStore.getEnabledRegistrationFields;

	const slugs = ['receive_internal_message', 'receive_emails', 'receive_calls', 'receive_sms'];

	slugs_array.value = _.chain(enabled_fields).filter(field => slugs.includes(field.slug)).map('slug').value();
};
</script>

<template>
	<div class="register-form-field label-show-mobile">
		<label>{{$t('account.register.contact_settings')}}:</label>
		<div class="register-info-radio" v-if="slugs_array.includes('receive_calls')">
			<label for="phone_radio">{{$t('general.phone')}}</label>
			<div class="register-radio-inner">
				<div class="radio-inner"><input type="radio" name="phone" value="yes"  v-model="registerStore.register_input_fields.receive_calls" />Yes</div>
				<div class="radio-inner"><input type="radio" name="phone" value="no"  v-model="registerStore.register_input_fields.receive_calls" />No</div>
			</div>
		</div>

		<div class="register-info-radio" v-if="slugs_array.includes('receive_sms')">
			<label for="sms_radio">{{ $t('general.sms') }}</label>
			<div class="register-radio-inner">
				<div class="radio-inner"><input type="radio" name="sms" value="yes" v-model="registerStore.register_input_fields.receive_sms" />Yes</div>
				<div class="radio-inner"><input type="radio" name="sms" value="no" v-model="registerStore.register_input_fields.receive_sms" />No</div>
			</div>
		</div>

		<div class="register-info-radio" v-if="slugs_array.includes('receive_emails')">
			<label for="email_radio">{{ $t('general.email') }}</label>
			<div class="register-radio-inner">
				<div class="radio-inner"><input type="radio" name="email" value="yes" v-model="registerStore.register_input_fields.receive_emails" />Yes</div>
				<div class="radio-inner"><input type="radio" name="email" value="no" v-model="registerStore.register_input_fields.receive_emails" />No</div>
			</div>
		</div>

		<div class="register-info-radio" v-if="slugs_array.includes('receive_internal_message')">
			<label for="website_radio">{{ $t('general.internal') }}</label>
			<div class="register-radio-inner">
				<div class="radio-inner"><input type="radio" name="website" value="yes" v-model="registerStore.register_input_fields.receive_internal_message" />Yes</div>
				<div class="radio-inner"><input type="radio" name="website" value="no" v-model="registerStore.register_input_fields.receive_internal_message" />No</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.register-form-main .register-outer-form .register-inner-form .register-info-radio .register-radio-inner .radio-inner input {
	cursor: pointer;
}
</style>